<template>
  <div>
    <tippy v-if="hasAssessments"
      arrow
      theme="light"
      placement="top">
      <div class="card narrow-line">
        <div class="card-header p-1">
          <span>Assessment History</span>
        </div>
        <div class="card-body p-1">
          <table class="table is-narrow is-fullwidth">
            <thead>
              <tr>
                <th />
                <th :colspan="numValuesInColumn"
                  class="has-vertical-middle">
                  <span class="badge badge-sm">Quoted</span>
                </th>
                <th :colspan="numValuesInColumn"
                  class="has-vertical-middle">
                  <span class="badge badge-sm">Authorised</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="assessment in itemAssessments"
                :key="getSupplementaryNo(assessment)">
                <td class="label-xxs has-vertical-middle">
                  <span v-show="getSupplementaryNo(assessment) === 0">Original</span>
                  <span v-show="getSupplementaryNo(assessment) > 0">Supp #{{ getSupplementaryNo(assessment) }}</span>
                </td>
                <td :colspan="hasTagValue(assessment[0]) ? numValuesInColumn : 1"
                  class="has-vertical-middle"
                  :class="hasTagValue(assessment[0]) ? 'has-text-centered' : ''">
                  <div v-if="assessment[0]">
                    <div v-if="assessment[0].isQuoteItemDeleted">
                      <span class="badge badge-sm tag is-danger">
                        Deleted
                      </span>
                    </div>
                    <div v-else-if="assessment[0].reportOnly">
                      <span class="badge badge-sm tag is-info">
                        Report Only
                      </span>
                    </div>
                    <div v-else>
                      <div>
                        <span v-if="numValuesInColumn > 1"
                          class="badge badge-sm">{{ $filters.formatNumber(assessment[0].hourValue) }}h
                        </span>
                        <span v-else
                          class="badge badge-sm">
                          {{ $filters.formatCurrency(totalValue(assessment[0]), $userInfo.locale) }}
                        </span>
                      </div>
                    </div>
                  </div>
                </td>
                <td v-if="numValuesInColumn > 1 && !hasTagValue(assessment[0])"
                  class="has-vertical-middle">
                  <span class="badge badge-sm">
                    {{ $filters.formatCurrency(totalValue(assessment[0]), $userInfo.locale) }}
                  </span>
                </td>
                <td :colspan="hasTagValue(assessment[1]) ? numValuesInColumn : 1"
                  class="has-vertical-middle"
                  :class="hasTagValue(assessment[1]) ? 'has-text-centered' : ''">
                  <div v-if="assessment[1]">
                    <div v-if="assessment[1].isQuoteItemDeleted">
                      <span class="badge badge-sm tag is-danger">
                        Deleted
                      </span>
                    </div>
                    <div v-else-if="assessment[1].reportOnly">
                      <span class="badge badge-sm tag is-info">
                        Report Only
                      </span>
                    </div>
                    <div v-else>
                      <div :class="getTextColor(assessment)">
                        <span v-if="numValuesInColumn > 1"
                          class="badge badge-sm">{{ $filters.formatNumber(assessment[1].hourValue) }}h
                        </span>
                        <span v-else
                          class="badge badge-sm">
                          {{ $filters.formatCurrency(totalValue(assessment[1]), $userInfo.locale) }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <span class="badge badge-sm tag is-warning">Pending</span>
                  </div>
                </td>
                <td v-if="numValuesInColumn > 1 && !hasTagValue(assessment[1])"
                  :class="getTextColor(assessment)">
                  <span v-if="assessment[1]"
                    class="badge badge-sm">
                    {{ $filters.formatCurrency(totalValue(assessment[1]), $userInfo.locale) }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </tippy>
    <div>
      <div class="supp-container">
        <span class="has-text-weight-semibold"
          :class="`is-size-${iconSize} ${supplementaryIconColour}`">{{ supplementaryNo > 0 ? supplementaryNo : 'O' }}</span>
        <span v-if="authorisedValueDiff > 0"
          class="change-icon"
          :class="`is-size-${iconSize + 3}`">
          <i class="has-text-success mdi mdi-arrow-up-circle" />
        </span>
        <span v-if="authorisedValueDiff < 0"
          class="change-icon"
          :class="`is-size-${iconSize + 3}`">
          <i class="has-text-danger mdi mdi-arrow-down-circle" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import storeMixin from '../storeMixin'
import { ItemCategoryCodeTypes } from '@/enums'
import { QuoteAssessmentMixin } from '../mixins'
import { QuotingMethodTypes } from '../../../enums'

export default {
  name: 'SupplementaryIcon',
  components: {},
  mixins: [storeMixin, QuoteAssessmentMixin],
  props: {
    item: {
      type: Object,
      default: null
    },
    itemAssessments: {
      type: Array,
      default: () => []
    },
    nextAssessmentNumber: {
      type: Number,
      default: null
    },
    quotingMethod: {
      type: String,
      default: null
    },
    iconSize: {
      type: Number,
      default: 4
    }
  },
  data: () => {
    return {
      authorisedValueDiff: 0,
      supplementaryNo: 0
    }
  },
  computed: {
    numValuesInColumn() {
      if (this.isItemlabour && this.quotingMethod === QuotingMethodTypes.Hour) {
        return 2
      }
      return 1
    },
    isItemlabour() {
      return ItemCategoryCodeTypes.itemTypes.filter((i) => i.valueCalculationType === 'Labour').some((c) => c.code === this.item.itemType)
    },
    hasAssessments() {
      return this.itemAssessments.length > 0
    },
    supplementaryIconColour() {
      let color = 'has-text-danger'
      if (this.itemChanged(this.item, this.item.lastQuotedAssessment, this.item.lastAuthorisedAssessment, this.quotingMethod)) {
        color = 'has-text-danger'
      }
      else if (this.item.lastAuthorisedAssessment) {
        color = 'has-text-success'
      }
      else if (this.item.lastQuotedAssessment) {
        color = 'has-text-warning'
      }
      return color
    }
  },
  watch: {
    item: {
      handler() {
        this.setSupplementaryNo()
        this.setAssessmentValueDiff()
      },
      deep: true
    }
  },
  created() {
    this.setSupplementaryNo()
    this.setAssessmentValueDiff()
  },
  updated() {},
  mounted() {},
  methods: {
    setAssessmentValueDiff() {
      if (this.itemChanged(this.item, this.item.lastQuotedAssessment, this.item.lastAuthorisedAssessment, this.quotingMethod)) {
        if ((this.item.deleted || this.item.isDeleted) && this.item.lastAuthorisedAssessment && !this.item.lastAuthorisedAssessment.isQuoteItemDeleted) {
          this.authorisedValueDiff = -1
        } else
        this.authorisedValueDiff = this.totalValue(this.item) - this.totalValue(this.item.lastAuthorisedAssessment)
      } else {
        this.authorisedValueDiff = this.getAssessmentDiff(this.item.lastQuotedAssessment, this.item.lastAuthorisedAssessment)
      }
    },
    setSupplementaryNo() {
      if (this.itemChanged(this.item, this.item.lastQuotedAssessment, this.item.lastAuthorisedAssessment, this.quotingMethod)) {
        this.supplementaryNo = this.nextAssessmentNumber
      } else {
        this.supplementaryNo = this.item.lastQuotedAssessment?.subQuoteNo || this.item.lastAuthorisedAssessment?.subQuoteNo
      }
    },
    getAssessmentDiff(quotedAssessment, authorisedAssessment) {
      if (!quotedAssessment && !authorisedAssessment) return null
      if (quotedAssessment && authorisedAssessment) {
        return this.totalValue(authorisedAssessment) - this.totalValue(quotedAssessment)
      } else if (authorisedAssessment && !quotedAssessment) {
        return 1 // a positive number
      } else {
        return null
      }
    },
    getSupplementaryNo(assessment) {
      if (assessment[0]) {
        return assessment[0].subQuoteNo
      } else if (assessment[1]) {
        return assessment[1].subQuoteNo
      }
      return null
    },
    totalValue(assessmentDetail) {
      if (!assessmentDetail) return null
      if (this.isItemlabour) {
        return assessmentDetail.dollarValue
      } else if (ItemCategoryCodeTypes.itemTypes.filter((c) => c.category === 'Part').some((c) => c.code === this.item.itemType)) {
        return assessmentDetail.itemQuantity * assessmentDetail.markupValue
      } else if (ItemCategoryCodeTypes.itemTypes.filter((c) => c.category === 'Misc' || c.category === 'Sublet').some((c) => c.code === this.item.itemType)) {
        return assessmentDetail.value
      }
    },
    hasTagValue(assessment) {
      if (assessment?.isQuoteItemDeleted || assessment?.reportOnly || !assessment) {
        return true
      } else {
        return false
      }
    },
    getTextColor(assessment) {
      let quotedAssessment = null
      let authorisedAssessment = null
      if (assessment.length === 2) {
        quotedAssessment = assessment[0]
        authorisedAssessment = assessment[1]
      }
      const diff = this.getAssessmentDiff(quotedAssessment, authorisedAssessment)
      if (diff > 0) {
        return 'has-text-success'
      } else if (diff < 0) {
        return 'has-text-danger'
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.narrow-line {
  line-height: 1;
  white-space: nowrap;
}

.supplement-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
}
.authorised-icon {
  height: 10px;
  width: 10px;
}
td {
  padding-left: 0.5rem !important;
}
.supp-container {
  position: relative;
}
.change-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -0.5em;
}
</style>
