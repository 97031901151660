<template>
  <div id="quoteSelection"
    class="columns">
    <!-- Butterfly -->
    <!-- Available Items -->
    <div class="column p-0 m-0 quote-builder-embedded maximised is-2"
      :class="[`quote-sub-side-menu-${$user.info.theme}`,{'minimised' : isSideSelectionPanelMinimised}]"
      v-if="sideSubMenu == null">

      <div class="min-max-button"
        @click="isSideSelectionPanelMinimised = !isSideSelectionPanelMinimised">
        <span class="mdi "
          :class="{'mdi-chevron-double-left' : !isSideSelectionPanelMinimised, 'mdi-chevron-double-right' : isSideSelectionPanelMinimised}" />
      </div>

      <div class="is-flex is-flex-wrap-nowrap is-flex-direction-row is-align-self-stretch content"
        style="height: 100%;">

        <div class="is-flex-grow-1"
          style=""
          :style="getSideColumnWidth()">

          <div id="butterfly-selections-heading"
            class="is-vcentered p-0 m-0 bb-1 pb-1">

            <button class="button p-0 pl-2 m-0 py-4 is-small is-responsive is-fullwidth is-justify-content-start is-size-7-s is-ellipsis"
              :class="{'is-primary': quotingMode === 'manual', 'has-text-white is-ghost': quotingMode !== 'manual'}"
              @click="setQuotingMode('manual')">
              <span class="is-ellipsis has-text-left">
                <i class="mdi mdi-keyboard mdi-20px pr-2" />
                Manual Quote
              </span>
            </button>

            <div :title="isTemplateDisabled ? 'Templates are currently not available for NTAR or LTAR quotes' : ''">
              <button class="button p-0 pl-2 m-0 py-4 is-small is-responsive is-fullwidth is-justify-content-start is-size-7-s is-ellipsis"
                :class="{'is-primary': quotingMode === 'template', 'has-text-white is-ghost': quotingMode !== 'template', 'disabled' : isTemplateDisabled}"
                @click="setQuotingMode('template')">
                <span class="is-ellipsis has-text-left">
                  <i class="mdi mdi-file-document-plus mdi-20px pr-2" />
                  Templates

                  <span v-if="isTemplateDisabled"
                    class="has-text-danger">LTAR/NTAR not supported yet</span>

                </span>
              </button>
            </div>

            <button class="button p-0 pl-2 m-0 py-4 is-small is-responsive is-fullwidth is-justify-content-start is-size-7-s is-ellipsis"
              :class="{'is-primary': quotingMode === 'section', 'has-text-white is-ghost': quotingMode !== 'section'}"
              @click="()=>setQuotingMode('section')">
              <span class="is-ellipsis has-text-left">
                <i class="mdi mdi-butterfly mdi-20px pr-2" />
                Vehicle Sections
              </span>
            </button>
          </div>

          <div id="butterfly-groups-header"
            class="is-flex is-flex-direction-row is-justify-content-space-between p-0 m-0 is-vcentered px-1 py-1"
            v-if="quotingMode == 'template'">

            <div class="is-flex-grow-1 is-flex-shrink-1 p-0 m-0 has-text-white is-ellipsis has-text-left">Templates</div>
          </div>

          <div id="butterfly-selections-list"
            v-if="quotingMode == 'template'"
            :style="getColumnHeightStyle('template')">
            <button v-for="group in quoteTemplates"
              :key="group.name"
              class="button p-0 m-0 is-fullwidth has-text-left py-4 is-small is-ellipsis has-text-white is-ghost is-size-7-s">

              <div class="is-flex-grow-0 is-flex is-flex-direction-row is-justify-content-space-between"
                style="width: 100%;">

                <button class="button is-square px-1 pt-2 m-0 is-size-7-s is-dark"
                  @click="deleteTemplate(group)"
                  :class="(templateObj.name == group.name ? 'is-primary' : 'is-dark')"
                  style="border-top-left-radius: 4px !important;border-bottom-left-radius: 4px !important; height: 36px;">
                  <!-- add to above   , { 'disabled' : (!$userInfo.isSystemAdministrator && !$userInfo.isSupportUser) } -->
                  <!-- permissions to delete -->
                  <i class="mdi mdi-delete mdi-14px"
                    title="Delete Template" />
                </button>

                <!-- vertical break -->
                <div class="is-flex-grow-0 is-flex is-flex-direction-row is-justify-content-space-between mt-1 has-background-grey"
                  style="width: 1px; height: 28px;" />

                <button class="is-flex-grow-1 button is-square p-0 py-4 m-0 is-size-7-s is-fullwidth is-ellipsis"
                  style="height: 36px;"
                  :class="(templateObj.name == group.name ? 'is-primary' : 'is-dark')"
                  @click="applyTemplate(group)">
                  <span :class="{'has-text-success': isAnyTemplateItemSelected(group.itemsIds, value)}"
                    title="Apply All Items from Template"
                    class="is-ellipsis has-text-left px-1">{{ group.name }}</span>
                </button>

                <!-- vertical break -->
                <div class="is-flex-grow-0 is-flex is-flex-direction-row is-justify-content-space-between mt-1 has-background-grey"
                  style="width: 1px; height: 28px;" />

                <button class="button"
                  @click="loadQuoteTemplate(group)"
                  title="Load Template and select items individually"
                  :class="(templateObj.name == group.name ? 'is-primary' : 'is-dark')">
                  <i class="mdi mdi-arrow-top-right-bold-box-outline mdi-22px"
                    style="font-weight: 0 !important;" />
                </button>

              </div>
            </button>
          </div>

          <div id="butterfly-groups-header"
            class="is-flex is-flex-direction-row is-justify-content-space-between p-0 m-0 is-vcentered px-1 py-1"
            v-if="quotingMode === 'section'">

            <div class="is-flex-grow-1 is-flex-shrink-1 p-0 m-0 has-text-white is-ellipsis has-text-left">
              <div class="columns p-0 m-0 px-1">
                <div class="column p-0 m-0">Selections</div>
                <div class="column p-0 m-0 pt-1 is-narrow button is-ghost is-small"
                  @click="toggleButterflyModal(true)">Modify
                </div>
              </div>
            </div>
          </div>

          <div class="column p-0 m-0"
            id="butterfly-list"
            :style="getColumnHeightStyle('butterfly')"
            v-if="quotingMode === 'section'">Ft
            <ul class="menu-list p-0 m-0">
              <li class="mb-1"
                v-for="group in selectedButterflyGroupsList"
                :key="group.partname">

                <div class="is-flex-grow-0 is-flex is-flex-direction-row is-justify-content-space-between">
                  <button class="button is-square px-1 pt-2 m-0 is-size-7-s"
                    :class="(group?.isSelected || 0) ? 'is-primary' : 'is-dark'"
                    style="border-top-left-radius: 4px !important;border-bottom-left-radius: 4px !important; height: 36px;"
                    @click="() => (!group.isDone) ? removeButterflyGroupSelection(group):''">
                    <i class="mdi mdi-close mdi-14px"
                      v-if="!group.isDone" />
                  </button>

                  <button class="is-flex-grow-1 button is-square p-0 py-4 m-0 is-size-7-s is-fullwidth is-ellipsis"
                    style="height: 36px;"
                    :class="(group?.isSelected || 0) ? 'is-primary' : 'is-dark'"
                    @click="toggleButterflyGroupSelection(group)">
                    <span class="is-ellipsis has-text-left">{{ group.partname }}</span>
                  </button>

                  <button class="is-flex-grow-0 button is-square p-0 py-4 m-0 is-size-7-s"
                    :class="(group?.isSelected || 0) ? 'is-primary' : 'is-dark'"
                    style="border-top-right-radius: 4px !important;border-bottom-right-radius: 4px !important; height: 36px;"
                    @click="toggleButterflyGroupSelection(group)">
                    <i class="mdi mdi-chevron-right mdi-30px"
                      style="font-weight: 0 !important;"
                      v-if="!(group?.isDone > 0)" />
                    <i class="mdi mdi-check-circle mdi-18px pt-1 pr-1"
                      v-if="group?.isDone > 0" />
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>

      </div>

    </div>

    <div class="is-flex-grow-1"
      v-if="isAvailableItemsActive">
      <div class="tile p-0 m-0 is-ancestor is-parent ">
        <div class="tile p-0 m-0">
          <div class="tile p-0 m-0 is-parent is-vertical ">
            <article class="tile p-0 m-0 is-child box dr-1 p-3">

              <div id="butterfly-items-header"
                class="columns p-0 m-0 is-vcentered">
                <div class="column is-four-fifths p-0 m-0 is-size-4 has-text-weight-bold">
                  Quote Builder
                </div>

                <div class="column p-0 m-0"
                  @click="toggleButterflyPart(false, false)">
                  <div class="is-flex is-justify-content-end">
                    <button class="button is-ghost p-0 m-0">
                      <i class=" mdi mdi-20px mdi-close" />
                    </button>
                  </div>
                </div>

              </div>

              <div id="butterfly-items-types-header"
                class="columns p-0 m-0 is-vcentered">

                <div class="column p-0 m-0 is-full">
                  <div class="tabs is-fullwidth">
                    <ul v-if="isAvailableItemsActive">
                      <li class=""
                        :class="{'is-active':selectedItemTab === name}"
                        v-for="(itemTypeTab, name) in availableItemsTypeTabs"
                        :key="name"
                        @click="setSelectedTab(name)">
                        <a class="px-0">
                          <span class="has-text-left mr-3 mt-3">{{ name }}</span>
                        </a>
                      </li>
                    </ul>
                    <ul v-if="!isAvailableItemsActive">
                      <li :class="{'is-active':selectedFilterItemTab === 'All'}"
                        @click="setSelectedFilterTab('All')">
                        <a class="">
                          <span class="has-text-left mr-3 mt-3">All </span>
                          <!--
                          <span v-show="getFilterItemCount('All')"
                            class="ml-3 has-badge-info has-badge-rounded has-badge-medium"
                            :data-badge="getFilterItemCount('All')" />
                            -->
                        </a>
                      </li>
                      <li :class="{'is-active':selectedFilterItemTab === name}"
                        v-for="(itemTypeTab, name) in availableItemsTypeTabs"
                        :key="name"
                        @click="setSelectedFilterTab(name)">
                        <a class="">
                          <span class="has-text-left mr-3 mt-3">{{ name }} </span>
                          <!--
                          <span v-show="getFilterItemCount(name)"
                            class="has-badge-info has-badge-rounded has-badge-medium"
                            :data-badge="getFilterItemCount(name)" />
                            -->
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="table-container-scroll table-container p-0 m-0"
                :style="getColumnHeightStyle('availableItems')">
                <bulma-table class="available-items-table table-highlight-header table-old is-narrow is-fullwidth p-0 m-0"
                  :columns="availableItemsColumns[availableItemsTypeTabs[selectedItemTab].columns]"
                  :page-index="filter.pageIndex"
                  :page-size="filter.pageSize"
                  :total-rows="getFilteredAvailableItems.length"
                  :is-loading="isAvailableItemsLoading"
                  :sort-column="filter.sortColumn"
                  :sort-order="filter.sortOrder"
                  @pageChanged="onPageChange"
                  @sort="sort"
                  :show-pagination="false">
                  <template v-if="['Labour'].indexOf(selectedItemTab) != -1">
                    <tr v-for="(item, index) in getFilteredAvailableItems"
                      :key="index"
                      :class="{ 'is-selected' : selectedRow === index }"
                      @click="highlightSelected(index, $event)">
                      <!-- <td>{{ item.itemNo }}</td> -->
                      <td class="is-ellipsis"
                        @click="addNewItems(item, availableItemsTypeTabs[selectedItemTab].itemSource, 'addSelection')">
                        <span class="is-ellipsis is-size-7-s"
                          v-html="highlightFilteredDescription(item.description)" />
                      </td>
                      <td class="has-text-right">
                        <div class="button p-0 m-0 is-small is-fullwidth"
                          :class="getSelectedAvailableClass(itemCategoryTypes.RR, item)"
                          @click="addNewItem(itemCategoryTypes.RR, item, 'addSelection')"
                          v-if="isAvailableValueShown(labourAllowTick[itemCategoryTypes.RR],item)">{{ getDisplayValue(itemCategoryTypes.RR, item) | formatNumber($userInfo.locale) }}
                        </div>
                      </td>
                      <td class="has-text-right">
                        <div class="button p-0 m-0 is-fullwidth is-small"
                          :class="getSelectedAvailableClass(itemCategoryTypes.REP, item)"
                          @click="addNewItem(itemCategoryTypes.REP, item, 'addSelection')"
                          v-if="isAvailableValueShown(labourAllowTick[itemCategoryTypes.REP],item)">{{ getDisplayValue(itemCategoryTypes.REP, item) | formatNumber($userInfo.locale) }}
                        </div>
                      </td>
                      <td class="has-text-right">

                        <div class="button p-0 m-0 is-fullwidth is-small"
                          :class="getSelectedAvailableClass(itemCategoryTypes.PAINT, item)"
                          @click="addNewItem(itemCategoryTypes.PAINT, item, 'addSelection')"
                          v-if="isAvailableValueShown(labourAllowTick[itemCategoryTypes.PAINT],item)">{{ getDisplayValue(itemCategoryTypes.PAINT, item) | formatNumber($userInfo.locale) }}
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="['Mech', 'Other Labour'].indexOf(selectedItemTab) != -1">
                    <tr v-for="(item, index) in getFilteredAvailableItems"
                      :key="index"
                      :class="{ 'is-selected' : selectedRow === index }"
                      @click="highlightSelected(index, $event)">
                      <!-- <td>{{ item.itemNo }}</td> -->
                      <td class="is-ellipsis"
                        @click="addNewItems(item, availableItemsTypeTabs[selectedItemTab].itemSource, 'addSelection')">
                        <span class="is-ellipsis is-size-7-s"
                          v-html="highlightFilteredDescription(item.description)" />
                      </td>

                      <td class="has-text-right">
                        <div class="button p-0 m-0 is-small is-fullwidth"
                          :class="getSelectedAvailableClass(itemCategoryTypes.MECH, item)"
                          @click="addNewItem(itemCategoryTypes.MECH, item, 'addSelection')"
                          v-if="getDisplayValue(itemCategoryTypes.MECH, item) != 0">{{ getDisplayValue(itemCategoryTypes.MECH, item) | formatNumber($userInfo.locale) }}
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="['Parts'].indexOf(selectedItemTab) != -1">
                    <tr v-for="(item, index) in getFilteredAvailableItems"
                      :key="index"
                      :class="[{ 'is-selected' : selectedRow === index }]"
                      @click="highlightSelected(index, $event)">
                      <!-- <td>{{ item.itemNo }}</td> -->
                      <td class="is-ellipsis"
                        @click="addNewItems(item, availableItemsTypeTabs[selectedItemTab].itemSource, 'addSelection')">
                        <span class="is-ellipsis is-size-7-s"
                          v-html="highlightFilteredDescription(item.description)" />
                      </td>
                      <td class="has-text-right">
                        <div class="button p-0 m-0 is-small is-fullwidth"
                          :class="getSelectedAvailableClass(itemCategoryTypes.PART, item)"
                          @click="addNewItem(itemCategoryTypes.PART, item, 'addSelection')">
                          <!-- {{ item.quantity }} -->
                          <i class="mdi mdi-plus mdi-16px" />
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="['Other Paint'].indexOf(selectedItemTab) != -1">
                    <tr v-for="(item, index) in getFilteredAvailableItems"
                      :key="index"
                      :class="[{ 'is-selected' : selectedRow === index }]"
                      @click="highlightSelected(index, $event)">
                      <!-- <td>{{ item.itemNo }}</td> -->
                      <td class="is-ellipsis"
                        @click="addNewItems(item, availableItemsTypeTabs[selectedItemTab].itemSource, 'addSelection')">
                        <span class="is-ellipsis is-size-7-s"
                          v-html="highlightFilteredDescription(item.description)" />
                      </td>

                      <td class="has-vertical-middle has-text-centered is-content-width">
                        <div class="button p-0 m-0 is-small is-fullwidth"
                          :class="getSelectedAvailableClass(itemCategoryTypes.PART, item)"
                          @click="addNewItem(itemCategoryTypes.OPG, item, 'addSelection')">
                          <span class="icon is-medium">
                            <i class="mdi mdi-plus mdi-24px" />
                          </span>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="['Misc'].indexOf(selectedItemTab) != -1">
                    <tr v-for="(item, index) in getFilteredAvailableItems"
                      :key="index"
                      :class="[{ 'is-selected' : selectedRow === index }]"
                      @click="highlightSelected(index, $event)">
                      <!-- <td>{{ item.itemNo }}</td> -->
                      <td class="is-ellipsis"
                        @click="addNewItems(item, availableItemsTypeTabs[selectedItemTab].itemSource, 'addSelection')">
                        <span class="is-ellipsis is-size-7-s"
                          v-html="highlightFilteredDescription(item.description)" />
                      </td>
                      <td class="has-text-right">
                        <div class="button p-0 m-0 is-small is-fullwidth"
                          :class="getSelectedAvailableClass(itemCategoryTypes.MISC, item)"
                          @click="addNewItem(itemCategoryTypes.MISC, item, 'addSelection')">
                          {{ item.price | formatNumber($userInfo.locale) }}
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="['Sublets'].indexOf(selectedItemTab) != -1">
                    <tr v-for="(item, index) in getFilteredAvailableItems"
                      :key="index"
                      :class="[{ 'is-selected' : selectedRow === index }]"
                      @click="highlightSelected(index, $event)">
                      <!-- <td>{{ item.itemNo }}</td> -->
                      <td class="is-ellipsis"
                        @click="addNewItems(item, availableItemsTypeTabs[selectedItemTab].itemSource, 'addSelection')">
                        <span class="is-ellipsis is-size-7-s"
                          v-html="highlightFilteredDescription(item.description)" />
                      </td>
                      <td class="has-text-right">
                        <div class="button p-0 m-0 is-small is-fullwidth"
                          :class="getSelectedAvailableClass(itemCategoryTypes.SUBL, item)"
                          @click="addNewItem(itemCategoryTypes.SUBL, item, 'addSelection')">
                          {{ item.price | formatNumber($userInfo.locale) }}
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template slot="empty">
                    <div class="content has-text-grey has-text-centered">
                      <span icon="icon is-large">
                        <i class="mdi mdi-48px mdi-emoticon-sad" />
                      </span>
                      <p v-if="(vehicle.model !== null && selectedItemTab === 'Parts') || (value.labourType !== '' && selectedItemTab === 'Labour')">Nothing</p>
                      <p v-if="(vehicle.model === null && selectedItemTab === 'Parts') || (value.labourType === '' && selectedItemTab === 'Labour')">
                        {{ vehicle.model === null && selectedItemTab === 'Parts' ? 'Vehicle is Required' : 'Labour Type is Required' }}
                        <br>
                        <router-link :to="{ name: routeTypes.QuoteHeader.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
                          active-class="is-active"
                          @click.native.prevent="goToRoute({ name: routeTypes.QuoteHeader.name, params: { quoteId : $route.params.quoteId }, query: $route.query })"
                          exact>
                          <span>
                            Click Here to Update
                          </span>
                        </router-link>
                      </p>
                    </div>
                  </template>
                </bulma-table>
              </div>

            </article>
          </div>
        </div>
      </div>
    </div>

    <!-- Items -->
    <div class="column p-0 m-0"
      :class="{'is-half': isAvailableItemsActive && sideSubMenu == null,
               'is-10': !isAvailableItemsActive && sideSubMenu == null && !isSideSelectionPanelMinimised, 'is-full': sideSubMenu != null || isSideSelectionPanelMinimised}">
      <div class="tile is-ancestor is-parent p-0 m-0">
        <div class="tile p-0 m-0">
          <div class="tile is-parent is-vertical p-0 m-0">
            <article class="tile is-child box detail-page-tile p-0 m-0"
              style="background-color: #F9F9F9;">

              <div class="columns p-0 m-0"
                style="margin-top: 12px !important">

                <div class="column p-0 m-0 is-full">
                  <div class="px-3 is-size-4 has-text-weight-bold"
                    style="position:relative;">
                    Quote Items

                    <div class="is-secondary is-size-7 button mt-2"
                      @click="handleAddingRandomItems()">
                      Add Random
                    </div>

                    <div class="button is-success"
                      v-if="value.parts.length > 0"
                      style="position:absolute;right: 25px;">Price Parts
                    </div>
                    <div v-if="partsCheckSetting && partsCheckSetting.property2 === '1'"
                      class="button"
                      style="position:absolute;right: 25px;"
                      @click="goToPriceParts()"
                      :class="{'button is-success': innerValue.parts.filter((p) => p.deleted === false && !p.isNew).length > 0}">
                      <span>Price Parts</span>
                      <span class="icon">
                        <i class="mdi mdi-arrow-right" />
                      </span>
                    </div>
                    <div v-if="partsCheckSetting && partsCheckSetting.property2 === '1'"
                      class="button"
                      style="position:absolute;right: 180px;"
                      @click="downloadPartsCheckPrice()"
                      :disabled="innerValue.parts.filter((p) => p.deleted === false && !p.isNew && (p.partStatus == 'Exp' || p.partStatus == 'Imp')).length > 0 ? false : true"
                      :class="{'button is-success': innerValue.parts.filter((p) => p.deleted === false && !p.isNew && (p.partStatus == 'Exp' || p.partStatus == 'Imp')).length > 0}">
                      <span>Download Prices</span>
                      <span class="icon">
                        <i class="mdi mdi-arrow-down" />
                      </span>
                    </div>
                  </div>
                  <div class="columns p-0 m-0">
                    <div class="column p-0 px-3 m-0"
                      :class="{'is-full': filteredOverviewItemTypeTabs.length == 1, 'is-half': filteredOverviewItemTypeTabs.length == 2, 'is-one-third': filteredOverviewItemTypeTabs.length == 3}"
                      v-for="(tabGroup, tabGroupIndex) in filteredOverviewItemTypeTabs"
                      :key="tabGroupIndex"
                      :style="getColumnHeightStyle('items')">
                      <div class="pb-2"
                        v-for="(selectedItemTabName, itemTypeIndex) in tabGroup"
                        :key="selectedItemTabName">
                        <quote-selection-type-table :class="{'mt-3':itemTypeIndex == 0}"
                          :vehicle="vehicle"
                          :value="innerValue"
                          :selected-item-tab="selectedItemTabName"
                          :item-type-tabs="itemTypeTabs"
                          :is-read-only-mode="isReadOnlyMode"
                          :display-deleted="displayDeleted"
                          :vendors="vendors"
                          :sublets="sublets"
                          :is-vendor-loading="isVendorLoading"
                          :vendor-filter="vendorFilter"
                          :selected-vendors="selectedVendors"
                          :search-vendor="searchVendor"
                          :select-vendor="selectVendor"
                          :show-present-as-modal="showPresentAsModal"
                          :open-sub-rows="openSubRows"
                          :toggle-sub-row="toggleSubRow"
                          :insurer="insurer"
                          :sorting-index="(tabGroupIndex+'').padStart(3, '0') + '_' + (itemTypeIndex+'').padStart(3, '0')" />
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </article>
          </div>
        </div>

        <butterfly-modal v-if="isButterflyModalActive"
          :active.sync="isButterflyModalActive"
          v-model="selectedButterflyCodes"
          @filterButterfly="()=>{toggleButterflyModal(true)}"
          @reset="reset"
          @done="filterButterly"
          @cancel="cancel">
          <div slot="text-content">
            <butterfly v-if="isButterflyModalActive"
              :show-repair-section="true"
              :selected-grouping="selectedGrouping"
              v-model="selectedButterflyCodes" />
          </div>
        </butterfly-modal>
        <emta-registration-modal v-if="isEmtaRegistrationModalActive"
          :active.sync="isEmtaRegistrationModalActive"
          icon-name="mdi-account-plus"
          icon-type="primary"
          @register="registerEmta"
          @cancel="cancelEmta" />
        <emta-vehicle-selection-modal v-if="isEmtaVehicleSelectionModalActive"
          :active.sync="isEmtaVehicleSelectionModalActive"
          v-model="emtaVehicle"
          icon-name="mdi-car"
          icon-type="primary"
          :make="make"
          :model="model"
          :year="year"
          @select="selectEmtaVehicle"
          @cancel="cancelEmtaVehicle" />
        <quote-item-present-as-modal v-if="isPresentAsModalActive"
          :active.sync="isPresentAsModalActive"
          v-model="presentAsItem.presentAs"
          @ok="closePresentAsModal()"
          @cancel="closePresentAsModal()"
          @close="closePresentAsModal()">
          <p slot="text-title">Present As</p>
        </quote-item-present-as-modal>

        <quote-template-modal v-if="templateObj.templateModalOpen"
          :template-obj="templateObj"
          :is-active="templateObj.templateModalOpen"
          @close="closeTemplateModal()"
          @selectTemplateItems="selectTemplateItems"
          :selected-template-items="templateObj.selectedTemplateItems"
          :item-type-tabs="itemTypeTabs">
          <p slot="text-title">Select Template</p>
        </quote-template-modal>
      </div>
    </div>

  </div>
</template>

<script>
/* eslint-disable */
import Guid from '@/components/Guid'
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash.debounce'
import QuoteService from './QuoteService'
import VendorService from '@/views/vendor/VendorService'
import { Butterfly, ButterflyModal, BufferflyGroupsList, ButterflyGroupGroupingsList } from '@/components/Butterfly'
import {
  QuoteItemDifferenceMixin,
  QuoteItemValidationMixin,
  QuoteLabourMixin,
  QuoteSelectionsColumnsMixin,
  QuoteSelectionsAvailableMixin,
  QuoteSelectionsCreateItemMixin
} from './mixins'

import { EventHubTypes, AppHeaderButtonTypes } from '@/enums'
import { PartTypes, ItemCategoryTypes, LabourTimeTypes, PaintGroupTypes, QuotingMethodTypes } from '@/enums'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { EmtaRegistrationModal, EmtaVehicleSelectionModal } from '@/components/emta'
import EmtaService from '@/components/emta/EmtaService'
import StoreMixin from './storeMixin'
import BulmaTable from '@/components/BulmaTable'
import draggable from 'vuedraggable'
import VueNumeric from '@/components/VueNumeric'
import { FocusInserted } from '@/components/directives'
import QuoteRoutes from './route-types'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import QuoteFiltersMixins from './QuoteFiltersMixins'
import Multiselect from 'vue-multiselect'
import QuoteSelectionTypeTable from './components/QuoteSelectionTypeTable.vue'
import { QuoteItemPresentAsModal } from '@/components/BulmaModal'
import AllowTicks from './allow-ticks.js'
import QuoteTemplateService from '@/services/QuoteTemplateService'
import QuoteTemplateModal from '../quotetemplate/QuoteTemplateModal.vue'
import QuoteTemplateView from '../quotetemplate/QuoteTemplateView.vue'
import { QuoteNotificationMixin } from './mixins'
import { PartsCheckService } from './services'

export default {
  name: 'QuoteSelections',
  filters: {},
  directives: { FocusInserted },
  components: {
    BulmaTable,
    Butterfly,
    ButterflyModal,
    EmtaRegistrationModal,
    EmtaVehicleSelectionModal,
    draggable,
    VueNumeric,
    Multiselect,
    QuoteSelectionTypeTable,
    QuoteItemPresentAsModal,
    QuoteTemplateModal,
    QuoteTemplateView
  },
  mixins: [
    NumberFiltersMixin,
    QuoteItemValidationMixin,
    QuoteLabourMixin,
    StoreMixin,
    QuoteFiltersMixins,
    QuoteSelectionsColumnsMixin,
    QuoteSelectionsAvailableMixin,
    QuoteSelectionsCreateItemMixin,
    QuoteItemDifferenceMixin,
    QuoteNotificationMixin
  ],
  props: {
    customer: null,
    vehicle: null,
    value: null,
    sideSubMenu: null,
    nextLineNumber: {
      type: Number,
      default: 0
    },
    make: {
      type: String,
      default: ''
    },
    model: {
      type: String,
      default: ''
    },
    year: {
      type: Number,
      default: 0
    },
    insurer: {
      type: Object,
      default: null
    }
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        //console.log('watch value, triggered')
        this.innerValue = this.value
      },
      deep: true
    },
    sideSubMenu: {
      handler: function (newVal, oldVal) {
        this.$nextTick(() => {
          this.calculateColumnHeights()
        })
      },
      deep: true
    },
    selectedButterflyCodes: {
      handler: function (newVal, oldVal) {
        console.log('watch.selectedButterflyCodes', newVal, oldVal)
        this.$eventHub.$emit(EventHubTypes.selectedButterflyGroupsChanged, {
          categories: this.selectedButterflyGroups,
          selectNextcategory: false
        })
      },
      deep: true
    },
    selectedButterflyGroupsList: {
      handler: function (newVal, oldVal) {
        console.log('watch.selectedButterflyGroupsList', newVal, oldVal)
        this.selectedButterflyParts = this.selectedButterflyGroupsList
        this.filter.butterflyCode = this.selectedButterflyParts
          .filter((r) => r.isSelected)
          .map((row) => row.partid)
          .join()
        this.getAvailableItems()
        //this.$eventHub.$emit(EventHubTypes.selectedButterflyPartsChanged, this.selectedButterflyGroupsList)
      },
      deep: true
    }
  },
  data() {
    let debouncedHandleFocusEvent = _debounce(this.handleFocusEvent, 10)

    let debouncedKeyPress = (evt) => {
      if (evt.code == 'Tab' || evt.code == 'Enter' || evt.code == 'NumpadEnter') {
        if (evt.type == 'keydown') {
          console.count('keydown event')

          console.log('handling keypress ' + ' ' + evt.type)
          console.log(evt)
          debouncedHandleFocusEvent(evt)
        }
        evt.preventDefault()
        return false
      }
    }
    //let debouncedKeyPress = this.handleFocusEvent

    return {
      /// Quote side selection panel ///
      isSideSelectionPanelMinimised: false,

      //// Templates ////
      templateObj: {
        templateModalOpen: false,
        templateName: '',
        templateList: [],
        selectedTemplateItems: []
      },
      //// Data ////
      quoteSelectionDropdownOpen: false,
      quotingMode: 'manual',
      quoteTemplates: [],
      selectedButterflyGroupsList: [],
      menuIndex: this.defaultMenuIndex,
      selectedGrouping: {},
      openSubRows: {},
      debouncedKeyPress,
      butterFlyHistory: [],
      isPresentAsModalActive: false,
      presentAsItem: null,
      displayDeleted: false,
      searchFilter: '',
      addBlankDropdownActive: false,
      innerValue: this.value,
      selectedRow: null,
      bufferflyGroupsList: BufferflyGroupsList,
      isButterflyModalActive: false,
      selectedButterflyCodes: [],
      selectedButterflyParts: [],
      selectedTimeType: this.value.labourType.toLowerCase(),
      isEmtaRegistrationModalActive: false,
      isEmtaVehicleSelectionModalActive: false,
      addedItems: [],
      selectedItemTab: 'Parts',
      selectedFilterItemTab: 'All',
      isAvailableItemsLoading: false,
      availableItems: [],
      filteredAvailableItems: [],
      sublets: [],
      isVendorLoading: true,
      baseVendors: [],
      vendors: [],
      selectedVendors: [],
      openSubRows: {},
      focusEventListner: null,
      isPricePartsModalActive: false,
      partsCheckSetting: null,
      headerHeight: {
        butterfly: '50vh',
        availableItems: '50vh',
        items: '50vh'
      },
      vendorFilters: [],
      vendorFilter: {
        id: Guid.empty(),
        name: '',
        pageIndex: 1,
        pageSize: 9999
      },
      availableItemsTypeTabs: {
        Parts: {
          itemSource: 'parts',
          columns: 'partColumns'
        },
        Labour: {
          itemSource: 'labours',
          columns: 'labourColumns'
        },
        Mech: {
          itemSource: 'others',
          columns: 'otherLabourColumns'
        },
        Misc: {
          itemSource: 'miscs',
          columns: 'miscColumns'
        },
        Sublets: {
          itemSource: 'sublets',
          columns: 'subletColumns'
        }
      },
      overviewItemTypeTabs: [
        ['R&R', 'Repair', 'Paint', 'Other Paint', 'Mech'],
        ['Parts', 'Misc', 'Sublets']
      ],
      itemTypeTabs: {
        'R&R': {
          name: 'Remove & Refit',
          key: ItemCategoryTypes.RR,
          nameShort: 'R&R',
          orderIndex: 0,
          itemType: ItemCategoryTypes.RR,
          itemSource: 'labours',
          columns: 'labourColumns',
          totals: { 'R&R': ItemCategoryTypes.RR },
          totalsColOffset: 7
        },
        /*
        RWA: {
          orderIndex: 1,
          itemType: ItemCategoryTypes.RWA,
          itemSource: 'labours',
          columns: 'labourColumns',
          totals: { RWA: ItemCategoryTypes.RWA },
          totalsColOffset: 7
        },
        */
        Repair: {
          name: 'Repair',
          key: ItemCategoryTypes.REP,
          orderIndex: 2,
          itemType: ItemCategoryTypes.REP,
          itemSource: 'labours',
          columns: 'labourColumns',
          totals: { Repair: ItemCategoryTypes.REP },
          totalsColOffset: 7
        },
        Paint: {
          name: 'Paint',
          key: ItemCategoryTypes.PAINT,
          orderIndex: 3,
          itemType: ItemCategoryTypes.PAINT,
          itemSource: 'labours',
          columns: 'labourColumns',
          totals: { Paint: ItemCategoryTypes.PAINT },
          totalsColOffset: 7
        },
        Parts: {
          name: 'Parts',
          key: ItemCategoryTypes.PART,
          orderIndex: 4,
          itemType: ItemCategoryTypes.PART,
          itemSource: 'parts',
          columns: 'partColumns',
          totals: { Parts: ItemCategoryTypes.PART },
          totalsColOffset: 9
        },
        /*
        'Other Paint': {
          orderIndex: 5,
          itemType: ItemCategoryTypes.OPG,
          itemSource: 'opgs',
          columns: 'opgColumns',
          totals: { 'Other Paint': ItemCategoryTypes.OPG },
          totalsColOffset: 9
        },
        */
        Mech: {
          name: 'Mechanical',
          orderIndex: 7,
          key: ItemCategoryTypes.MECH,
          itemType: ItemCategoryTypes.MECH,
          itemSource: 'others',
          columns: 'otherLabourColumns',
          totals: { Mech: ItemCategoryTypes.MECH },
          totalsColOffset: 6
        },
        /*
        'Other Labour': {
          orderIndex: 8,
          itemType: (type) => [ItemCategoryTypes.FIBER, ItemCategoryTypes.CRUSH, ItemCategoryTypes.CD].indexOf(type) !== -1,
          itemSource: 'others',
          columns: 'otherLabourColumns',
          totals: { Fiber: ItemCategoryTypes.FIBER, Crush: ItemCategoryTypes.CRUSH, CD: ItemCategoryTypes.CD },
          totalsColOffset: 6
        },
        */
        Misc: {
          name: 'Miscellaneous',
          key: ItemCategoryTypes.MISC,
          nameShort: 'Misc',
          orderIndex: 6,
          itemType: ItemCategoryTypes.MISC,
          itemSource: 'miscs',
          columns: 'miscColumns',
          totals: { Misc: ItemCategoryTypes.MISC },
          totalsColOffset: 6
        },
        Sublets: {
          name: 'Sublets',
          key: ItemCategoryTypes.SUBL,
          orderIndex: 5,
          itemType: ItemCategoryTypes.SUBL,
          itemSource: 'sublets',
          columns: 'subletColumns',
          totals: { Sublet: ItemCategoryTypes.SUBL },
          totalsColOffset: 8
        }
      },
      filter: {
        countryId: Guid.empty(),
        companyId: this.value.companyId,
        vehicleId: this.value.vehicleId,
        query: '',
        butterflyCode: '',
        scheduleId: this.vehicle.scheduleId || '',
        bodyId: this.vehicle.bodyId || '',
        bodySize: '',
        paintGroup: this.vehicle.paintGroup || '',
        variantId: 0,
        nvic: '',
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 9999,
        type: ''
      },
      emtaVehicle: {
        makeId: '',
        modelId: '',
        year: null,
        variantId: '',
        nvic: ''
      }
    }
  },
  computed: {
    getFilteredAvailableItems() {
      let count = this.selectedButterflyParts.filter((r) => r.isSelected).length

      if (count > 0) {
        return this.filteredAvailableItems?.filter((item) => !!item.description && item.description !== '')
      }

      return []
    },
    butterflyGroupGroupingsList() {
      return ButterflyGroupGroupingsList
    },
    routeTypes() {
      return QuoteRoutes
    },
    labourAllowTick() {
      return AllowTicks.LabourAllowTick
    },
    filteredOverviewItemTypeTabs() {
      let useFiltered = !this.isAvailableItemsActive || this.sideSubMenu !== null
      console.log('zzzzzzz', useFiltered, this.selectedFilterItemTab)
      if (useFiltered) {
        switch (this.selectedFilterItemTab) {
          case 'Parts':
            return [['Parts']]
          case 'Labour':
            return [
              ['R&R', 'Repair'],
              ['Paint', 'Other Paint']
            ]
          case 'Mech':
            return [['Mech']]
          case 'Misc':
            return [['Misc']]
          case 'Sublets':
            return [['Sublets']]
          case 'All':
          default:
            return this.overviewItemTypeTabs
        }
      } else {
        return [this.overviewItemTypeTabs.reduce((set, row) => [...set, ...row], [])]
      }
    },
    orderedItemTypeTabs() {
      let a = Object.keys(this.itemTypeTabs)
        .map((name) => {
          var clone = _cloneDeep(this.itemTypeTabs[name])
          clone.name = name
          return clone
        })
        .sort((a, b) => a.sortIndex < b.sortIndex)
        .map((r) => r.name)

      return a
    },
    isReadOnlyMode() {
      return this.innerValue.readOnly || this.readOnlyView
    },
    totals() {
      const itemCategoryTypeMapping = {
        RR: 'rrTotal',
        REP: 'repairTotal',
        RWA: 'rwaTotal',
        PAINT: 'paintTotal',
        OPG: 'opgTotal',
        MECH: 'mechTotal',
        CD: 'cdTotal',
        CRUSH: 'crushTotal',
        FIBER: 'fgTotal',
        PART: 'partTotal',
        MISC: 'miscTotal',
        SUBL: 'sublTotal',
        PDRRR: 'pdrrrTotal',
        PDRRE: 'pdrreTotal',
        OPG: 'opgTotal'
      }

      const totals = {}
      for (var row of Object.keys(itemCategoryTypeMapping)) {
        totals[row] = this.innerValue[itemCategoryTypeMapping[row]]
      }
      return totals
    },
    partTypes() {
      return PartTypes
    },
    paintGroupTypes() {
      return PaintGroupTypes
    },
    existingItems() {
      const lineSources = [...new Set(Object.values(this.itemTypeTabs).map((row) => row.itemSource))]
      let existingItems = {}
      for (let lineSource of lineSources) {
        for (let i of this.innerValue[lineSource]) {
          if (i.deleted) {
            continue
          }
          existingItems[[i.itemType, i.itemDesc, i.itemNo].join()] = true
        }
      }

      return existingItems
    },
    quoteRoutes() {
      return QuoteRoutes
    },
    quotingMethodTypes() {
      return QuotingMethodTypes
    },
    totalColSpan() {
      return 3
      //return this.columns[this.itemTypeTabs[this.selectedItemTab].columns].length
    },
    selectedButterflyPartCodes() {
      return this.selectedButterflyPartCodes.map((row) => row.partid)
    },
    vehicleBuildYear() {
      const buildYear = this.vehicle.buildYear ? Number(this.vehicle.buildYear) : this.vehicle.model.startYear ? Number(this.vehicle.model.startYear) : 0
      return buildYear
    },
    isAvailableItemsActive() {
      return !this.innerValue.readOnly && !this.readOnlyView && this.selectedButterflyParts.filter((r) => r.isSelected).length > 0
      //this.sideSubMenu === 'QuoteSelections'
    },
    isAvailableItemsMainActive() {
      return this.value.subQuoteNo > 0 && !this.innerValue.readOnly && !this.readOnlyView && this.selectedButterflyParts.filter((r) => r.isSelected).length > 0
    },
    selectedButterflyGroups() {
      const list = []
      for (let group of this.bufferflyGroupsList) {
        for (let part of group) {
          if (this.selectedButterflyCodes.indexOf(part.partid) !== -1) {
            list.push(part)
          }
        }
      }

      return list
    },

    ///disable templates if labour type Ntar or Ltar
    isTemplateDisabled() {
      return this.value?.labourType === LabourTimeTypes.NTAR || this.value?.labourType === LabourTimeTypes.LTAR
    }
  },
  async created() {
    this.filter.companyId = this.$userInfo.companyId
    this.emtaVehicle = _cloneDeep(this.emtaVehicleStore)
    this.getAvailableItems()
    this.getSublets()
    await this.getBaseVendors()

    this.getVendors()

    this.getQuoteTemplates()

    this.partsCheckSetting = await QuoteService.getExternalSetting('partscheck')
    /*
    this.selectedButterflyCodes = Object.keys(
      BufferflyGroupsList.reduce((s, r) => {
        r.reduce((x, rr) => {
          x[rr.partid] = true
          return x
        }, s)
        return s
      }, {})
    )
    */
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.debouncedKeyPress)
    document.removeEventListener('keyup', this.debouncedKeyPress)
    let quoteSelection = document.getElementById('quoteSelection')
    if (quoteSelection) {
      quoteSelection.removeEventListener('click', this.handleMouseClick)
    }
  },

  mounted() {
    // recreateNode(document, true)

    document.addEventListener('keydown', this.debouncedKeyPress)
    document.addEventListener('keyup', this.debouncedKeyPress)
    document.getElementById('quoteSelection').addEventListener('click', this.handleMouseClick)

    this.$root.$on('scroll-to-item', (data) => {
      this.scrollToItem(data.item, data.action)
    })

    this.$eventHub.$on(EventHubTypes.AppDetailHeaderButtonClicked, (data) => {
      this.$nextTick(() => {
        if (data != AppHeaderButtonTypes.Save) {
          return
        }
        console.log('triggered Saved Click', this.$v.entity.$anyError)
        this.scrollToValidationError()
      })
    })

    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      //console.log('triggered EventHubTypes.EntitySaved')
      this.innerValue = this.value
    })

    this.$v.entity.labours.$touch()

    this.$eventHub.$on(EventHubTypes.selectedButterflyGroupsChanged, (data) => {
      var list = data.categories

      let selectionCount = 0
      this.selectedButterflyGroupsList = _cloneDeep(
        list.map((row, index) => {
          if (row.isSelected === undefined) {
            row.isSelected = false
          }

          let foundItems = this.selectedButterflyGroupsList.filter((r) => r.partid == row.partid && r.isSelected === true)

          if (foundItems.length > 0) {
            row.isSelected = true
            row.isDone = foundItems[0].isDone
          }

          return row
        })
      )

      if (selectionCount === 0 && data.selectNextcategory) {
        for (let row of this.selectedButterflyGroupsList) {
          if (!row.isDone) {
            row.isSelected = true
            break
          }
        }
      }

      this.updateSectionsState()
      this.calculateColumnHeights()
    })

    this.$eventHub.$on(EventHubTypes.selectedButterflyCodesChanged, (list) => {
      this.selectedButterflyCodes = list
    })

    this.$eventHub.$on(EventHubTypes.toggleButterflyModal, (state) => {
      this.isButterflyModalActive = state
    })

    this.$eventHub.$on(EventHubTypes.fixPendingValudaion, (state) => {
      this.scrollToValidationError()
    })

    this.$eventHub.$on(EventHubTypes.reloadTemplates, (state) => {
      this.getQuoteTemplates()
    })

    this.calculateColumnHeights()
  },
  methods: {
    scrollToValidationError() {
      if (!this.$v.entity.$anyError) {
        return
      }

      for (let tab of Object.values(this.itemTypeTabs)) {
        //console.log('Validation Error', tab.itemSource, this.$v.entity[tab.itemSource].$anyError)
        if (!this.$v.entity[tab.itemSource].$anyError) {
          continue
        }

        //console.log('error in', tab.itemSource, this.$v.entity[tab.itemSource])
        let errors = Object.values(this.$v.entity[tab.itemSource].$each.$iter).filter((r) => {
          //console.log('e', r.$anyError)
          return r.$anyError
        })

        //console.log('errors', errors)
        for (let error of errors) {
          //console.log('error', error)
          for (let field in error) {
            if (field.startsWith('$')) {
              continue
            }
            //console.log(field)

            this.scrollToItem(error.$model, { name: 'validation', field: field })
            return
          }
        }
      }
    },

    handleMouseClick(evt) {
      evt.preventDefault()
      evt.stopPropagation()
      let target = evt.target

      let isInButton = target.closest('.button')

      if (
        ['focus-trigger', 'button', 'modal-background'].some((r) => target.classList.contains(r.toLowerCase())) ||
        ['input', 'select', 'label'].indexOf(target.tagName.toLowerCase()) != -1 ||
        isInButton
      ) {
        return
      }

      const isAnyRowsOpen = Object.values(this.openSubRows).some((r) => !!r)
      if (isAnyRowsOpen) this.closeSubRows()

      return false
    },
    setQuotingMode(value) {
      if (this.quotingMode != 'section' && value == 'section' && this.selectedButterflyGroupsList.length == 0) {
        this.toggleButterflyModal(true)
      } else if (this.quotingMode == 'section' && value == 'section') {
        this.toggleButterflyModal(true)
      }

      this.quotingMode = value

      this.$nextTick(() => {
        this.calculateColumnHeights()
      })
    },
    async getQuoteTemplates() {
      this.quoteTemplates = await QuoteTemplateService.getQuoteTemplates()

      const template = await QuoteTemplateService.getQuoteTemplates()

      console.log('template', template)
    },
    async loadQuoteTemplate(template) {
      this.templateObj.templateModalOpen = true
      let quoteTemplateItems = await QuoteTemplateService.getQuoteTemplateItems(template.id)

      let quoteItemIds = []

      Object.values(this.itemTypeTabs).forEach((tab) => {
        this.value?.[tab.itemSource]?.forEach((item) => {
          if (!item.deleted) {
            quoteItemIds.push(item.itemDesc + '-' + item.itemType)
          }
        })
      })
      this.templateObj.selectedTemplateItems = quoteItemIds

      this.templateObj.name = template.name
      this.templateObj.templateList = quoteTemplateItems
    },
    selectTemplateItems(items) {
      items.add.forEach((item) => {
        let newItem = {
          itemNo: item.itemNo,
          description: item.itemDesc,
          quantity: item.quantity,
          hourValue: item.value,
          unitPrice: 0,
          tradePrice: item.value,
          partNo: item.partNo,
          side: item.side || 'L'
        }
        this.addNewItem(item.itemType, newItem, 'addTemplate')
      })

      console.log('add items here', items.add)
      console.log('add items here', items.remove)

      let count = 0

      Object.values(this.itemTypeTabs).forEach((tab) => {
        this.value?.[tab.itemSource]?.forEach((item) => {
          this.$nextTick(() => {
            if (items.remove.includes(item.itemDesc + '-' + item.itemType)) {
              this.deleteItem(item)
            }
          })
        })
      })
      this.closeTemplateModal()
    },
    closeTemplateModal() {
      this.templateObj.templateModalOpen = false
      this.templateObj.selectedTemplateItems = []
      this.templateObj.name = ''
    },
    isAnyTemplateItemSelected(templateItemIds, quoteObj) {
      const quoteItemIds = []
      // looping over each QuoteItem category i.e repair, parts, labour etc
      Object.values(this.itemTypeTabs).forEach((tab) => {
        quoteObj[tab.itemSource]?.forEach((item) => {
          if (!item.deleted && templateItemIds.includes(item.itemDesc + '-' + item.itemType)) {
            quoteItemIds.push(item)
          }
        })
      })
      return !!quoteItemIds.length
    },
    getSideColumnWidth() {
      let widthValue = '100%'
      // if (this.isAvailableItemsActive) {
      //   widthValue = '30%'
      // }
      return { 'max-width': widthValue, 'min-width': widthValue, width: widthValue }
    },
    getColumnHeightStyle(name) {
      let value = this.headerHeight[name]
      return {
        height: `calc(100vh - ${value}px)`,
        'min-height': `calc(100vh - ${value}px)`,
        'max-height': `calc(100vh - ${value}px)`,
        'overflow-y': 'auto'
      }
    },
    calculateColumnHeights() {
      let heights = {
        butterfly: [
          document.getElementById('main-nav-menu'),
          document.getElementById('detail-nav-menu'),
          document.getElementById('main-sub-nav-menu'),
          document.getElementById('butterfly-selections-heading'),
          document.getElementById('butterfly-selections-list'),
          document.getElementById('butterfly-groups-header'),
          64 // PADDINGS,
        ],
        template: [
          document.getElementById('main-nav-menu'),
          document.getElementById('detail-nav-menu'),
          document.getElementById('main-sub-nav-menu'),
          document.getElementById('butterfly-selections-heading'),
          105 // PADDINGS,
        ],
        availableItems: [
          document.getElementById('main-nav-menu'),
          document.getElementById('detail-nav-menu'),
          document.getElementById('main-sub-nav-menu'),
          180 // PADDING
        ],
        items: [112, document.getElementById('main-nav-menu'), document.getElementById('detail-nav-menu')]
      }

      for (let name in heights) {
        this.headerHeight[name] = heights[name].reduce((total, row) => {
          if (row === null) {
            return total
          }

          let value = row
          if (row.offsetHeight !== undefined) {
            value = row.offsetHeight
          }
          return total + value
        }, 0)
      }
    },
    toggleButterflyModal(state) {
      this.$eventHub.$emit(EventHubTypes.toggleButterflyModal, state)
    },
    clearButterflyGroupSelections() {
      this.$eventHub.$emit(EventHubTypes.selectedButterflyCodesChanged, [])
    },
    toggleGrouping(group) {
      let temp = _cloneDeep(this.selectedGrouping)
      temp[group.name] = !(temp[group.name] ?? false)
      this.selectedGrouping = temp

      let selectedItems = this.selectedButterflyGroupsList.reduce((s, r) => {
        s[r.partid] = true
        return s
      }, {})

      if (temp[group.name]) {
        for (let r of group.items) {
          selectedItems[r] = true
        }

        selectedItems = Object.keys(selectedItems)
      } else {
        let thingsToRemove = _cloneDeep(group.items)
        for (let row of this.butterflyGroupGroupingsList) {
          if (!this.selectedGrouping[row.name]) {
            continue
          }

          for (var item of row.items) {
            let position = thingsToRemove.indexOf(item)
            if (position == -1) {
              continue
            }
            thingsToRemove.splice(position, 1)
          }
        }

        selectedItems = Object.keys(selectedItems)
        for (let item of thingsToRemove) {
          let position = selectedItems.indexOf(item)
          if (position == -1) {
            continue
          }
          selectedItems.splice(position, 1)
        }
      }
      this.$eventHub.$emit(EventHubTypes.selectedButterflyCodesChanged, selectedItems)
    },
    resetButterflyGroupSelections() {
      let items = Object.keys(
        BufferflyGroupsList.reduce((s, r) => {
          r.reduce((x, rr) => {
            x[rr.partid] = true
            return x
          }, s)
          return s
        }, {})
      )

      this.$eventHub.$emit(EventHubTypes.selectedButterflyCodesChanged, items)
    },
    updateSectionsState(newCodesList) {
      if (newCodesList === undefined) {
        newCodesList = Object.keys(
          this.selectedButterflyGroupsList.reduce((s, r) => {
            s[r.partid] = true
            return s
          }, {})
        )
      }

      let temp = _cloneDeep(this.selectedGrouping)
      for (let row of this.butterflyGroupGroupingsList) {
        if (!this.selectedGrouping[row.name]) {
          continue
        }

        var found = false
        for (let item of row.items) {
          if (newCodesList.includes(item)) {
            found = true
            break
          }
        }

        if (!found) {
          temp[row.name] = false
        }
      }
      this.selectedGrouping = temp
    },
    closeButterflySelections() {
      this.selectedButterflyGroupsList = this.selectedButterflyGroupsList.map((row) => {
        row.isSelected = false
        return row
      })
    },
    removeButterflyGroupSelection(group) {
      const newCodesList = this.selectedButterflyGroupsList.filter((row) => row.partid != group.partid).map((row) => row.partid)
      this.$eventHub.$emit(EventHubTypes.selectedButterflyCodesChanged, newCodesList)
    },
    toggleButterflyGroupSelection(group) {
      let temp = _cloneDeep(this.selectedButterflyGroupsList)
      temp.map((i) => {
        if (i.partname == group.partname) {
          i.isSelected = !(i?.isSelected || false)
        } else {
          i.isSelected = false
        }

        return i
      })

      this.selectedButterflyGroupsList = temp
    },
    changeQuote(subQuote) {
      this.$router.push({
        name: this.$route.name,
        params: { quoteId: subQuote.value }
      })
    },
    isAvailableValueShown(allowTickValue, item) {
      if (item.allowTick === undefined) {
        return true
      }

      return (item.allowTick & allowTickValue) == allowTickValue
    },
    closePresentAsModal() {
      this.isPresentAsModalActive = false
    },
    showPresentAsModal(item, index) {
      this.presentAsItem = item
      this.$nextTick(() => {
        this.isPresentAsModalActive = true
      })
    },
    searchVendor: _debounce(async function (query, index) {
      this.isVendorLoading = true
      this.vendorFilters[index] = {
        id: Guid.empty(),
        name: query,
        pageIndex: 1,
        pageSize: 50
      }
      this.vendors[index] = await VendorService.getVendorDropdownProto2(this.vendorFilters[index])
      this.isVendorLoading = false
    }, 500),
    getFilterItemCount(name) {
      if (name == 'All') {
        return Object.keys(this.availableItemsTypeTabs).reduce((total, name) => {
          return total + this.getFilterItemCount(name)
        }, 0)
      }

      return this.innerValue[this.availableItemsTypeTabs[name].itemSource].filter((r) => r.deleted == 0).length
    },
    scrollToItem(item, action) {
      let c = this.$children
        .filter((r) => r.$options._componentTag == 'quote-selection-type-table')
        .forEach((r) => {
          let quoteItemId = item.quoteItemId

          if (r.$refs[quoteItemId] === undefined) {
            return
          }

          if (r.$refs[quoteItemId].length == 0) {
            console.log('ref issue')
            return
          }

          console.log('scrolling to target', quoteItemId, item, r, r.$refs)
          let target = r.$refs[quoteItemId][0]
          target.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
          })

          this.toggleSubRow(item, action)

          let fieldTargets = []
          if (action.name == 'validation') {
            fieldTargets = target.querySelectorAll(`.focus-target[data-target="${action.field}"]`)
            if (fieldTargets.length == 0) {
              fieldTargets = target.querySelectorAll('.focus-target[data-target="itemDesc"]')
            }
          } else if (this.isBlankItem(item)) {
            fieldTargets = target.querySelectorAll('.focus-target[data-target="itemDesc"]')
          } else {
            switch (item.itemType) {
              case ItemCategoryTypes.FIBER:
              case ItemCategoryTypes.CRUSH:
              case ItemCategoryTypes.CD:
              case ItemCategoryTypes.MECH:
              case ItemCategoryTypes.RR:
              case ItemCategoryTypes.RWA:
              case ItemCategoryTypes.REP:
              case ItemCategoryTypes.PAINT:
              case ItemCategoryTypes.OPG:
                fieldTargets = target.querySelectorAll('.focus-target[data-target="hourValue"]')
                break
              case ItemCategoryTypes.MISC:
              case ItemCategoryTypes.SUBL:
                fieldTargets = target.querySelectorAll('.focus-target[data-target="miscTotal"]')
                break
              case ItemCategoryTypes.PART:
                fieldTargets = target.querySelectorAll('.focus-target[data-target="partValue"]')
                break
              default:
                break
            }
          }

          if (fieldTargets.length > 0) {
            this.$nextTick(() => {
              fieldTargets[0].focus()
              fieldTargets[0].select()
            })
          }
        })
    },
    handleFocusEvent(evt) {
      function getTr(element) {
        if (element.nodeName.toLowerCase() == 'tr') {
          return element
        }

        return getTr(element.parentElement)
      }

      let getItemFromElement = (e) => {
        let tr = getTr(e)
        let itemType = tr.dataset.quoteItemType
        let quoteItemId = tr.dataset.quoteItemId
        let listName = this.determineMethodFromType(itemType).list
        let item = this.innerValue[listName].filter((r) => r.quoteItemId == quoteItemId)

        if (item.length != 1) {
          throw 'This shouldnt happen must have an existing item????'
        }
        item = item[0]
        return item
      }

      let typeTables = [...document.querySelectorAll('.item-type-table')]
      typeTables = typeTables.reduce((set, r) => {
        let sortIndex = r.dataset.sortIndex
        set[sortIndex] = {
          itemType: r.dataset.itemType,
          items: []
        }

        let focusTargets = [...r.querySelectorAll('.focus-target')]
        focusTargets = focusTargets.filter((e) => {
          let tr1 = getTr(e)
          let tr2 = getTr(tr1.parentElement)
          return !tr2.classList.contains('filtered-out')
        })
        focusTargets = focusTargets.sort((a, b) => {
          let aSortIndex = a.dataset.sortIndex
          let bSortIndex = b.dataset.sortIndex

          if (aSortIndex != bSortIndex) {
            return aSortIndex.localeCompare(bSortIndex)
          }

          let aR = a.getBoundingClientRect()
          let bR = b.getBoundingClientRect()

          if (aR.top != bR.top) {
            return aR.top > bR.top
          }

          return aR.left > bR.left
        })
        focusTargets = focusTargets.reduce((lines, row) => {
          let sortIndex = row.dataset.sortIndex
          if (lines[sortIndex] == undefined) {
            lines[sortIndex] = []
          }
          lines[sortIndex].push(row)
          return lines
        }, {})

        set[sortIndex].items = focusTargets

        return set
      }, {})
      let typeTablesKeys = Object.keys(typeTables)
      console.log('typeTables', typeTables)

      let currentElement = document.activeElement
      let currentSortIndex = currentElement.dataset.sortIndex
      if (currentSortIndex === undefined) {
        let firstTypeTable = typeTables[typeTablesKeys[0]]
        if (firstTypeTable.items.length == 0) {
          // CREATE LINE
          this.addBlankItem(firstTypeTable.itemType)
        } else {
          let lineKeys = Object.keys(firstTypeTable.items)
          let firstLine = firstTypeTable.items[lineKeys[0]]
          let firstElement = firstLine[0]

          let targetItem = getItemFromElement(firstElement)
          this.$nextTick(() => {
            this.toggleSubRow(targetItem, 'enterPress')
            this.$nextTick(() => {
              firstElement.focus()
            })
          })
        }
        evt.preventDefault()
        return false
      }

      let currentItem = getItemFromElement(currentElement)
      let lineIndex = currentElement.dataset.sortIndex
      let tableIndex = lineIndex.substr(0, 7)
      let fieldIndex = typeTables[tableIndex].items[lineIndex].indexOf(currentElement)
      let currentTable = typeTables[tableIndex]
      let currentLines = currentTable.items
      let currentLine = currentLines[lineIndex]
      let isEndOfLine = fieldIndex == currentLine.length - 1
      let isLastLine = Object.keys(currentLines).indexOf(lineIndex) == Object.keys(currentLines).length - 1

      if (isEndOfLine) {
        if (isLastLine) {
          if (!this.isBlankItem(currentItem)) {
            this.addBlankItem(currentTable.itemType)
            evt.preventDefault()
            return false
          }

          this.deleteItem(currentItem)

          let nextTable = (function () {
            let position = typeTablesKeys.indexOf(tableIndex)
            if (position == typeTablesKeys.length - 1) {
              return typeTables[typeTablesKeys[0]]
            } else {
              return typeTables[typeTablesKeys[position + 1]]
            }
          })()

          let nextTableItemKeys = Object.keys(nextTable.items)
          if (nextTableItemKeys.length > 0) {
            let targetElement = nextTable.items[nextTableItemKeys[0]][0]
            let targetItem = getItemFromElement(targetElement)
            this.$nextTick(() => {
              this.toggleSubRow(targetItem, 'enterPress')
              this.$nextTick(() => {
                targetElement.focus()
              })
            })
          } else {
            this.addBlankItem(nextTable.itemType)
          }
          evt.preventDefault()
          return false
        } else {
          // GOTO NEXT LINE
          let lineKeys = Object.keys(currentLines)
          let position = lineKeys.indexOf(lineIndex)
          let targetElement = currentLines[lineKeys[position + 1]][0]
          let targetItem = getItemFromElement(targetElement)
          this.$nextTick(() => {
            this.toggleSubRow(targetItem, 'enterPress')
            this.$nextTick(() => {
              targetElement.focus()
            })
          })
          evt.preventDefault()
          return false
        }
      } else {
        // NOT END OF LINE
        let targetElement = currentLine[fieldIndex + 1]
        targetElement.focus()
      }

      evt.preventDefault()
      return false
    },

    // CORE
    getItems(selectedItemTabFilter) {
      if (this.innerValue) {
        return this.innerValue[selectedItemTabFilter.itemSource] //.filter((i) => !i.deleted)
      }
      return []
    },
    getFilteredItems(itemTabFilter) {
      return this.getItems(itemTabFilter).filter((i) => {
        if (itemTabFilter.itemType instanceof Function) {
          return itemTabFilter.itemType(i.itemType)
        }
        return i.itemType == itemTabFilter.itemType
      })
    },
    filterAvailableItems() {
      if (this.searchFilter == '') {
        this.filteredAvailableItems = this.availableItems
        return
      }

      let filterParts = this.searchFilter.toUpperCase().split(/\s+/)
      this.filteredAvailableItems = this.availableItems
        .filter((row) => {
          let ucaseDescription = row.description.toUpperCase()
          let count = 0
          let score = 0
          for (let part of filterParts) {
            let position = ucaseDescription.indexOf(part)
            if (position !== -1) {
              count++
              score += 100 - position
            }
          }
          row.score = count * 10000 + score
          return count > 0
        })
        .sort((a, b) => {
          if (a.score == b.score) {
            return a.description < b.description
          } else {
            return a.score < b.score
          }
        })
    },
    getTabTotal(selectedItemTab) {
      const targetTotals = Object.values(this.itemTypeTabs[selectedItemTab].totals)
      return targetTotals.reduce((total, totalName) => {
        return (total += this.totals[totalName])
      }, 0)
    },
    highlightFilteredDescription(value) {
      if (this.searchFilter == '') {
        return value
      }

      let selected = Array(value.length).fill(0)
      let filterParts = this.searchFilter.toUpperCase().split(/\s+/)
      let ucaseValue = value.toUpperCase()

      let found = false
      for (let part of filterParts) {
        let position = ucaseValue.indexOf(part)
        let length = part.length
        if (position !== -1) {
          found = true
          selected = selected.fill(1, position, position + length)
        }
      }

      if (!found) {
        return value
      }

      let output = ''
      let lastState = null
      for (let i = 0; i < selected.length; i++) {
        let s = selected[i]

        if (lastState != s) {
          if (lastState != null) {
            output += '</span>'
          }
          output += '<span' + (s ? ' class="has-text-info"' : '') + '>'
          lastState = s
        }

        output += value[i]
      }
      return output + '</span>'
    },
    async getAvailableItems() {
      let type = this.availableItemsTypeTabs[this.selectedItemTab].itemSource
      type = type[0].toUpperCase() + type.slice(1)
      let functionName = 'getAvailable' + type

      console.log('functionName', functionName)

      let items = await this[functionName]()
      this.availableItems = items
      this.filterAvailableItems()
    },
    setSelectedTab(name) {
      this.selectedItemTab = name
      this.getAvailableItems()
    },
    setSelectedFilterTab(name) {
      this.selectedFilterItemTab = name
    },
    getDefaultRate(item, index) {
      let rate = this.isNtar ? this.innerValue.rates[0] : this.innerValue.rates.find((r) => r.labourCodeId === item.opgCode)
      return rate.rate
    },
    async selectVendor(selected, index, item) {
      const vendorFilter = {
        id: selected.id,
        name: '',
        pageIndex: 1,
        pageSize: 50
      }
      this.vendorFilters.splice(index, 1, vendorFilter)
      this.vendors[index] = await VendorService.getVendorDropdownProto2(vendorFilter)
      // this.items[index].vendorId = selected.id
      // this.items[index].vendorName = selected.name
      const itemToUpdate = this.innerValue.sublets.find((i) => i.quoteItemId === item.quoteItemId)
      itemToUpdate.vendorId = selected.id
      itemToUpdate.vendorName = selected.name
    },
    async getVendor(index, filter) {
      let itemVendors
      if (filter.vendorId !== Guid.empty()) {
        itemVendors = await VendorService.getVendorDropdownProto2(filter)
      } else {
        itemVendors = this.baseVendors
      }
      this.vendors.splice(index, 1, itemVendors)
      this.vendorFilters.splice(index, 1, filter)
      this.selectedVendors.splice(index, 1, filter.id !== Guid.empty() ? itemVendors[0] : null)
    },
    initArrays(length) {
      this.vendors = new Array(length)
      this.vendorFilters = new Array(length)
      this.selectedVendors = new Array(length)
      let size = length
      while (size--) {
        this.vendors.splice(size, 1, null)
        this.vendorFilters.splice(size, 1, null)
        this.selectedVendors.splice(size, 1, null)
      }
    },
    async getVendors() {
      this.isVendorLoading = true
      const vm = this
      let items = this.getFilteredItems(this.getTabFromType(ItemCategoryTypes.SUBL))

      this.initArrays(items)
      const promises = items.map(async function (item, index) {
        const vendorId = item.vendorId || Guid.empty()
        const vendorFilter = {
          id: vendorId,
          name: '',
          pageIndex: 1,
          pageSize: 50
        }
        vm.getVendor(index, vendorFilter)
      })
      await Promise.all(promises)
      this.isVendorLoading = false
    },
    async getBaseVendors() {
      const filter = {
        id: Guid.empty(),
        name: '',
        pageIndex: 1,
        pageSize: 50
      }
      this.baseVendors = await VendorService.getVendorDropdownProto2(filter)
    },
    async getSublets() {
      this.sublets = await QuoteService.getSubletsProto(!this.value.audatex)
    },
    toggleButterflyPart(isDoneCategory = true, selectNextcategory = true) {
      let newSelectedButterflyParts = _cloneDeep(this.selectedButterflyGroupsList)

      if (!isDoneCategory && !selectNextcategory) {
        this.selectedButterflyGroupsList = this.selectedButterflyGroupsList.map((row) => {
          row.isSelected = false
          return row
        })
      } else {
        if (this.sideSubMenu === 'QuoteSelections') {
          this.butterFlyHistory = newSelectedButterflyParts
        } else {
          newSelectedButterflyParts = this.butterFlyHistory
          this.butterFlyHistory = []
        }

        if (this.sideSubMenu !== 'QuoteSelections') {
          this.$eventHub.$emit(EventHubTypes.subSidePanelChanged, 'QuoteSelections')
        } else {
          this.$eventHub.$emit(EventHubTypes.subSidePanelChanged, null)
        }

        this.selectedButterflyGroupsList = newSelectedButterflyParts
        this.calculateColumnHeights()
      }
    },
    getSelectedAvailableClass(itemType, item) {
      let output = []
      output.push('is-info')
      let id = [itemType, item.description, item.itemNo].join()
      if (this.existingItems[id] === undefined) {
        output.push('is-outlined')
      }

      if (this.getDisplayValue(itemType, item) == 0) {
        output.push('has-text-grey-light')
      }

      return output
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.getAvailableItems()
    },
    cancelEmtaVehicle() {
      this.isEmtaVehicleSelectionModalActive = false
    },
    selectEmtaVehicle() {
      this.setEmtaVehicle(_cloneDeep(this.emtaVehicle))
      this.getAvailableItems()
      this.isEmtaVehicleSelectionModalActive = false
    },
    async isEmtaRegistered() {
      const result = await EmtaService.isEmtaRegistered()
      return result
    },
    emtaTest() {
      this.isEmtaVehicleSelectionModalActive = !this.isEmtaVehicleSelectionModalActive
    },
    getOpgRate(item, index) {
      if (this.value.quotingMethod === QuotingMethodTypes.Hour) {
        let rate = this.isNtar ? this.innerValue.rates[0] : this.innerValue.rates.find((r) => r.labourCodeId === item.opgCode)
        if (!rate) {
          const labourType = this.$labourTypes.find((t) => t.labourTypeCode === PaintGroupTypes.PAINT)
          const newRate = {
            quoteId: this.innerValue.quoteId,
            labourCodeId: item.opgCode,
            labourTypeId: labourType.labourTypeId,
            rate: this.isNtar ? this.innerValue.shopRate : 0,
            modifiedBy: '',
            modifiedDate: null,
            createdBy: '',
            createdDate: null,
            isNew: true,
            quoteVersion: 0,
            deleted: false
          }
          this.innerValue.rates.push(newRate)
          rate = newRate
        }
        // this.items[index].rate = rate.rate
        // this.items[index].dollarValue = this.items[index].hourValue * this.items[index].rate
        item.rate = rate.rate
        item.dollarValue = roundAwayFromZero(this.items[index].hourValue * this.items[index].rate)
      }
      this.$emit('opg-code-changed')
      this.updateTotal()
    },
    sort(name, order) {},
    reset() {
      this.selectedButterflyCodes = []
      this.filter.query = ''
      this.filter.butterflyCode = ''
      this.filter.pageIndex = 1
      this.getAvailableItems()
    },
    filterButterly() {
      this.isButterflyModalActive = false
    },
    toggleButterflyModal(state) {
      this.isButterflyModalActive = state
    },
    cancel(value) {
      this.selectedButterflyCodes = value
      this.isButterflyModalActive = false
    },
    highlightSelected(index, event) {
      // this.selectedRow = index
    },
    updateTotal(index, item) {
      //this.isFloatingWidgetActive = true
    },
    getMajorColumnWidthClass(index) {
      switch (index) {
        case 0:
          return { 'is-two-fifths': this.isAvailableItemsActive, 'is-half': !this.isAvailableItemsActive }
        case 1:
          return { 'is-three-fifths': this.isAvailableItemsActive, 'is-half': !this.isAvailableItemsActive }
        case 2:
          return { 'is-three-fifths': this.isAvailableItemsActive, 'is-full': !this.isAvailableItemsActive }
        default:
          throw 'Unknown Column Index ' + index
      }
    },
    closeSubRows() {
      let temp = _cloneDeep(this.openSubRows)

      // ONLY ONE SUB ROW
      for (let i in temp) {
        temp[i] = 0
      }
      this.openSubRows = temp
    },
    toggleSubRow(item, mode) {
      this.closeSubRows()
      let temp = _cloneDeep(this.openSubRows)
      if (mode.name !== undefined) {
        mode = mode.name
      }

      let value = 0
      switch (mode) {
        case 'addBlank':
          value = 1
          break
        case 'downArrow':
          if (!item || temp[item.quoteItemId] == 2) {
            value = 0
          } else {
            value = 2
          }
          break
        case 'done':
          value = 0
          break
        case 'addSelection':
          value = 1
          break
        case 'addTemplate':
          value = 1
          break
        case 'copyTo':
          value = 0
          break
        case 'enterPress':
          value = 1
          break
        case 'rowClick':
          value = 1
          break
        case 'deleteItem':
          value = 0
          break
        case 'validation':
          value = 2
          break
        default:
          throw "Uknown mode '" + mode + "'"
      }

      if (item) {
        temp[item.quoteItemId] = value
      }
      this.openSubRows = temp
    },
    toggleOverlayPanel(name) {
      if (name === undefined) {
        name = null
      }

      this.$eventHub.$emit(EventHubTypes.subSidePanelChanged, name)
    },
    goToPriceParts() {
      if (this.innerValue.parts.filter((p) => p.deleted === false && p.isNew).length > 0) {
        this.$notification.openNotificationWithType(
          'danger',
          'Price Parts',
          'You have added new part item(s), save quote before you start PartsCheck Price request'
        )
        return false
      }
      if (this.innerValue.parts.filter((p) => p.deleted === false).length > 0) {
        this.isPricePartsModalActive = true
        this.$router.push({
          name: QuoteRoutes.QuotePriceParts.name
        })
      } else this.isPricePartsModalActive = true
    },
    async downloadPartsCheckPrice() {
      if (!this.snapshotDiff) {
        this.$showSpinner('Fetching prices...')
        try {
          const countryCode = this.$company.info.countryCode
          const priceUpdate = await PartsCheckService.getPartsPrices(this.innerValue.quoteId)
          const vm = this
          if (priceUpdate.parts.length > 0) {
            let nextLineNumber = this.nextLineNumber
            priceUpdate.parts.forEach(function (partsCheckPart) {
              let part
              if (partsCheckPart.isNew) {
                part = new QuoteItemModel(vm.innerValue.quoteId, partsCheckPart.itemNo, partsCheckPart.itemDesc, ItemCategoryTypes.PART)
                part.sortNo = vm.innerValue.parts.length ? Math.max(...vm.innerValue.parts.map((i) => i.sortNo)) + 1 : 1
                if (vm.innerValue.isAudaNet) {
                  part.lineNumber = 0
                } else {
                  part.lineNumber = nextLineNumber
                }
                if (vm.isAudanet) {
                  part.rev = QuoteItemRevTypes.NonAudaNetPart
                }
              } else {
                part = vm.innerValue.parts.find((i) => i.quoteItemId === partsCheckPart.quoteItemId)
                part.itemStatus = 'C'
              }

              if (countryCode !== 'MY' || (countryCode === 'MY' && partsCheckPart.isNew)) {
                part.value = partsCheckPart.unitPrice
                part.markupPercent = partsCheckPart.markupPercent
                part.markupValue = partsCheckPart.markupPrice
                part.mark = partsCheckPart.mark
              }

              part.itemQuantity = partsCheckPart.quantity
              part.buyPrice = partsCheckPart.buyPrice
              part.partNo = partsCheckPart.partNo
              part.side = partsCheckPart.side
              part.extLineId = partsCheckPart.partsCheckId
              part.partStatus = 'Imp'
              if (part.isNew) {
                if (countryCode === 'MY') {
                  part.itemComment = 'Part added by supplier'
                  part.reportOnly = true
                } else {
                  part.itemComment = partsCheckPart.itemComment
                  part.reportOnly = partsCheckPart.reportOnly
                }
                vm.innerValue.parts.splice(vm.innerValue.parts.length, 1, part)
                if (!vm.innerValue.isAudaNet) {
                  vm.innerValue.lines = vm.nextLineNumber + 1
                  nextLineNumber++
                }
              }
            })
            this.showSuccessToast('PartsCheck price updates downloaded', 5000)
          } else {
            this.$toast.open({
              message: 'There are no PartsCheck price updates',
              type: 'is-warning',
              queue: true,
              duration: 5000
            })
            this.$eventHub.$emit(EventHubTypes.EntityReload)
          }
        } catch {
        } finally {
          this.$hideSpinner()
        }
      } else {
        this.showQuoteChangedToast()
      }
    },
    async deleteTemplate(template) {
      this.$showSpinner('Deleting template...')
      try {
        await QuoteTemplateService.deleteQuoteTemplate(template.id)
        this.$toast.open({
          message: 'Template deleted',
          type: 'is-success',
          queue: true,
          duration: 5000
        })
        this.getQuoteTemplates()
      } catch {
      } finally {
        this.$hideSpinner()
      }
    },
    async applyTemplate(template) {
      this.$showSpinner('Applying Templates ...')

      const templateItems = await QuoteTemplateService.getQuoteTemplateItems(template.id)

      const itemTypes = Object.values(this.itemTypeTabs).map((tab) => tab.key)

      const templateItemsFiltered = templateItems
        .filter((item) => {
          return itemTypes.includes(item.itemType)
        })
        ?.sort((a, b) => a.sortNo - b.sortNo)

      try {
        let quoteItemIds = []
        Object.values(this.itemTypeTabs).forEach((tab) => {
          this.value?.[tab.itemSource]?.forEach((item) => {
            if (!item.deleted && !quoteItemIds.includes(item.itemDesc + '-' + item.itemType)) {
              quoteItemIds.push(item.itemDesc + '-' + item.itemType)
            }
          })
        })
        const templateItemsToAdd = templateItemsFiltered.filter((item) => !quoteItemIds.includes(item.itemDesc + '-' + item.itemType))

        if (templateItemsToAdd.length === 0) {
          this.$toast.open({
            message: 'Template already applied',
            type: 'is-warning',
            queue: true,
            duration: 5000
          })
          return
        } else {
          this.selectTemplateItems({ add: templateItemsToAdd, remove: [] })
        }
      } catch {
      } finally {
        this.$hideSpinner()
      }
    },
    async handleAddingRandomItems() {
      this.$showSpinner('Adding random items...')

      const itemTypesToGet = Object.values(this.itemTypeTabs)

      // randonly pick one item type
      const randomItemType = itemTypesToGet[Math.floor(Math.random() * itemTypesToGet.length)]

      let type = randomItemType.itemSource[0].toUpperCase() + randomItemType.itemSource.slice(1)

      let functionName = 'getAvailable' + type

      let items = await this[functionName](true)

      const numberItemsToSelect = items.length < 5 ? items.length : 5

      // select 5 random items
      const randomItems = items.sort(() => 0.5 - Math.random()).slice(0, numberItemsToSelect)

      console.log('randomItems', randomItems)

      // // todo: make batch funtion that manipulates the array instead of looping through it
      randomItems.forEach((item) => {
        let hour = 0

        if (randomItemType.itemType === 'MECH') {
          hour = item.mechHour
        }

        if (randomItemType.itemType === 'PAINT') {
          hour = item.paintHour
        }

        if (randomItemType.itemType === 'RR') {
          hour = item.rrHour
        }

        if (randomItemType.itemType === 'PART') {
          hour = item.value
        }

        let newItem = {
          itemNo: item.itemNo,
          description: item.description,
          quantity: 1,
          hourValue: hour,
          unitPrice: 0,
          tradePrice: item.value,
          partNo: item.partNo,
          side: item.side || 'L'
        }

        console.log('newItem', newItem)
        this.addNewItem(randomItemType.itemType, newItem, 'addTemplate')
      })

      this.$toast.open({
        message: 'Random items added',
        type: 'is-success',
        queue: true,
        duration: 5000
      })

      this.$hideSpinner()

      // const randomItems = await QuoteService.getRandomisedItems(this.value.quoteNo, itemTypesToGet)

      // console.log(randomItems)

      // this.$showSpinner('Adding random items...')
      // try {
      //   // const items = randomItems.map((item) => {
      //   //   return new QuoteItemModel(this.value.quoteId, item.itemNo, item.itemDesc, item.itemType)
      //   // })
      //   // this.value.parts = this.value.parts.concat(items)
      //   // this.$toast.open({
      //   //   message: 'Random items added',
      //   //   type: 'is-success',
      //   //   queue: true,
      //   //   duration: 5000
      //   // })
      // } catch {
      // } finally {
      //   this.$hideSpinner()
      // }
    }
  }
}
</script>

<style lang="scss" scoped >
.is-size-7-s {
  font-size: 15px !important;
}
.linenumber-input {
  width: 60px;
}
.itemno-input {
  max-width: 2.5em;
}
.itemdesc-input {
  min-width: 20em;
}
.money-input {
  max-width: 6.5em;
}
.itemstatus-input {
  width: 50px;
}
tfoot {
  tr {
    th {
      &:not(:last-child) {
        border-right: none;
      }
    }
  }
  tr:not(:last-child) {
    th {
      border-top: none;
      border-bottom: none;
    }
  }
}
.item-type-tag {
  width: 52px;
}
.present-as-tags {
  display: inline-flex;
  .tag {
    font-size: 0.6rem !important;
  }
}

@import '../..//assets/style/themes/default/_variables.scss';

.available-items-table {
  thead tr th {
    background-color: #f9f9f9 !important;
  }

  border-collapse: separate;
  border-spacing: 0px 6px;
  background-color: #f9f9f9;
  tbody tr {
    height: 50px;
    background-color: white;
    td {
      border-top: 1px solid #dce1e5 !important;
      border-bottom: 1px solid #dce1e5 !important;
    }

    td:first-of-type {
      border-left: 1px solid #dce1e5 !important;
    }

    td:last-of-type {
      border-right: 1px solid #dce1e5 !important;
    }
  }

  th,
  td {
    vertical-align: middle;
  }
}

.table-old {
  thead tr th {
    background-color: #f9f9f9 !important;
  }
  tbody tr td {
    border-radius: 0px !important;
  }
}

.tabs {
  font-size: 12px;
  font-weight: bold;

  a {
    border-bottom-color: $primary !important;
  }

  li.is-active a {
    color: $primary !important;
    border-bottom-width: 3px;
    border-bottom-color: black !important;
  }
}

.button.is-square {
  border-radius: 0 !important;
}

.quote-builder-embedded {
  position: relative;

  .min-max-button {
    height: 20px;
    width: 20px;
    border-radius: 0px 10px 10px 0px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 2px;
    right: -20px;
    z-index: 1;
    background: $grey-light;
    cursor: pointer;

    &:hover {
      background: $primary;
      width: 30px;
      right: -30px;
      transition: all 0.3s;
    }
  }

  &.minimised {
    width: 0px;
    // opacity: 0;
    transition: all 0.3s ease-in-out;
    .content {
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }
  }

  &.maximised {
    // width: 100%;
    // opacity: 1;
    transition: all 0.3s ease-in-out;
  }
}
</style>

<style lang="scss">
table {
  position: relative;
  thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
  }
}
</style>