import { render, staticRenderFns } from "./AuthoriseView.vue?vue&type=template&id=6163b4a6&scoped=true"
import script from "./AuthoriseView.vue?vue&type=script&lang=js"
export * from "./AuthoriseView.vue?vue&type=script&lang=js"
import style0 from "./AuthoriseView.vue?vue&type=style&index=0&id=6163b4a6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6163b4a6",
  null
  
)

export default component.exports