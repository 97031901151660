<template>
  <bulma-table v-if="vehicle && itemTypeTabs[selectedItemTab] !== undefined"
    class="table-new fixed-layout is-narrow table-highlight-header item-type-table layout-fixed"
    :columns="columns[itemTypeTabs[selectedItemTab].columns]"
    :page-index="filter.pageIndex"
    :page-size="filter.pageSize"
    :total-rows="activeItems.length"
    :is-loading="false"
    :sort-column="filter.sortColumn"
    :sort-order="filter.sortOrder"
    :draggable="true"
    :show-pagination="false"
    :data-sort-index="sortingIndex"
    :data-item-type="itemTypeTabs[selectedItemTab]?.itemType"
    :row-data="{tab: itemTypeTabs[selectedItemTab], name: selectedItemTab, data: activeItems, $userInfo, innerValue}">
    <template>
      <draggable v-model="innerValue[itemTypeTabs[selectedItemTab].itemSource]"
        v-bind="{'disabled': !isRowDraggable, chosen: 'chosen', ghostClass: 'draggable-ghost', dragClass: 'draggable-drag'}"
        :tag="'tbody'"
        @change="itemMoved"
        @end="endMove"
        handle=".item-drag-handle">
        <tr v-for="(item, index) in activeItems"
          :key="item.quoteItemId"
          @click="(e)=>{e.preventDefault(); e.stopPropagation(); return false;}">
          <td class="p-0 m-0"
            :colspan="getFullColspan()">
            <table class="table is-fullwidth item-row-table layout-fixed"
              :style="{backgroundColor: openSubRows[item.quoteItemId] ? '#CED4DA !important' : ''}"
              style="height: 50px;"
              :class="{'has-error': itemHasError(item)}">
              <tbody>
                <tr :key="item.quoteItemId + '_main'"
                  :data-quote-item-id="item.quoteItemId"
                  :data-quote-item-type="item.itemType"
                  @click="(e)=>openSubRow(e,item,'rowClick')"
                  :data-itemid="item.quoteItemId"
                  :ref="item.quoteItemId"
                  :class="[isItemRemoved(item, getItemAssessments(item)) ? 'strike-through-line-quoting' : '']">

                  <!-- DESCRIPTION -->
                  <td class=" itemdesc-input"
                    :colspan="getDescriptionColSpan(item)"
                    :class="[defaultCellColourClass(item,'itemDesc'), defaultRowColourClass(item)]"
                    :content="getTooltipCommunicationHistoryForField(item, 'itemDesc')"
                    :style="isNtar && (isType(itemCategoryTypes.REP,item) || isType(itemCategoryTypes.RWA,item)) ? 'width: 25% !important' : getColumnWidth('itemDesc')"
                    v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'itemDesc')">
                    <!-- x{{ item.quoteItemId }}x -->
                    <div class="columns is-vcentered">

                      <!-- EXPANDO THINGIE-->
                      <div class="column is-narrow pr-2 no-strike-through-line-quoting">
                        <button class="button is-small is-inverted is-ghost"
                          :disabled="isEditableField(item, 'expandSubRow', index)"
                          @click="(e) => {toggleSubRow((openSubRows[item.quoteItemId] ?? 0) < 2 ? item : null,'downArrow');e.preventDefault();e.stopPropagation();return false;}">
                          <span class="icon is-small">
                            <i class="mdi mdi-24px"
                              :class="{'mdi-chevron-right': (openSubRows[item.quoteItemId] ?? 0) < 2, 'mdi-chevron-down': openSubRows[item.quoteItemId] == 2 }" />
                          </span>
                        </button>
                      </div>

                      <!-- Part-->
                      <div class="column pl-0 overflow-hidden"
                        :class="[isItemRemoved(item, getItemAssessments(item)) ? 'strike-through-line-quoting' : '']">

                        <input class="input focus-target"
                          data-target="itemDesc"
                          :maxlength="150"
                          :title="'Description: ' + item.itemDesc"
                          :data-sort-index="getSelectionIndex(index)"
                          :class="[defaultRowColourClass(item), !isValidItemDescNew(item) ? 'is-danger': '', hideFieldClass(item,'itemDesc', index, 0) ]"
                          placeholder="Item Description"
                          v-focus-inserted="{ focus: $userInfo.scrollItemLabour }"
                          v-model="item.itemDesc"
                          @input="setItemStatus(item)">

                        <span class="focus-trigger is-size-7-s "
                          style="overflow: hidden; width: 100%;"
                          data-target="itemDesc"
                          :title="'Description: ' + item.itemDesc"
                          :class="hideFieldClass(item,'itemDesc', index, 1)">{{ coalesce(item.itemDesc,"Description") }}</span>

                        <div v-if="isType(itemCategoryTypes.PART, item)"
                          class="overflow-hidden"
                          :class="defaultCellColourClass(item,'partNo')"
                          :content="getTooltipCommunicationHistoryForField(item, 'partNo')"
                          style="width: 100%"
                          v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'partNo')">
                          <input class="input is-uppercase focus-target"
                            style=" width: 100%;"
                            :title="'Part No. ' + item.partNo"
                            data-target="partNo"
                            :maxlength="24"
                            :data-sort-index="getSelectionIndex(index)"
                            :class="[defaultRowColourClass(item), hideFieldClass(item, 'partNo', index, 0)]"
                            placeholder="Part No."
                            :disabled="item.deleteOtherItem"
                            v-model="item.partNo">

                          <span v-if="!isItemRemoved(item, getItemAssessments(item))"
                            class="focus-trigger is-size-7-s"
                            :class="hideFieldClass(item, 'partNo', index, 1)"
                            :title="'Part No: ' + item.partNo"
                            data-target="partNo">{{ coalesce(item.partNo, 'Part Number') }}</span>
                        </div>

                        <div v-if="item.itemComment"
                          class="help is-primary is-size-7-s">
                          <span class="has-text-weight-bold">Comment: </span>
                          <span>{{ item.itemComment }}</span>
                        </div>
                      </div>
                    </div>
                  </td>

                  <!-- All: loadingPercent -->
                  <td v-if="isNtar && (isType(itemCategoryTypes.REP,item) || isType(itemCategoryTypes.RWA,item))"
                    class="has-vertical-middle has-text-right is-content-width"
                    :class="[isItemRemoved(item, getItemAssessments(item)) ? 'strike-through-line-quoting' : '']"
                    title="Loading Percent"
                    style="width: 16%;">
                    <div v-show="!item.isLoadItem && item.itemType !== itemCategoryTypes.RR && item.itemType !== itemCategoryTypes.PAINT"
                      class="select"
                      :class="[hideFieldClass(item,'loadingPercent', index, 0)]">
                      <select v-model="item.loadingPercent"
                        :class="defaultRowColourClass(item)"
                        @change="changeLoadingPercent(item)">
                        <option :value="0">0%</option>
                        <option :value="10">10%</option>
                        <option :value="25">25%</option>
                      </select>
                    </div>
                    <span :class="hideFieldClass(item,'loadingPercent', index, 1)">{{ item.loadingPercent + '%' }}</span>
                  </td>

                  <!-- All: reportOnly -->
                  <td :class="[defaultCellColourClass(item,'reportOnly'), {'vertical-top': isEditableField(item,'reportOnly', index) && isSource(['parts'],item)}, isItemRemoved(item, getItemAssessments(item)) ? 'strike-through-line-quoting' : '' ]"
                    :content="getTooltipCommunicationHistoryForField(item, 'reportOnly')"
                    :style="getColumnWidth('reportOnly')"
                    v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'reportOnly')">
                    <div v-show="!isItemRemoved(item, getItemAssessments(item))"
                      class="is-size-7-s cursor-pointer"
                      :class="item.reportOnly ? 'has-text-primary' : 'has-text-grey-light'"
                      @click="(e)=>handleReportOnly(e, item)">
                      <span class="mdi mdi-22px report-icon"
                        :title="'Report Only:  ' + (item.reportOnly ? 'Y' : 'N')"
                        :class="!item.reportOnly ? 'mdi-alpha-r-circle-outline' : 'mdi-alpha-r-circle'" />
                    </div>
                  </td>

                  <td v-if="isType(itemCategoryTypes.REP,item) ||isType(itemCategoryTypes.RR,item) || isType(itemCategoryTypes.PAINT,item) || isType(itemCategoryTypes.MECH,item)"
                    :class="[isItemRemoved(item, getItemAssessments(item)) ? 'strike-through-line-quoting' : '']"
                    :style="getColumnWidth('rate')">
                    <div v-if="innerValue.quotingMethod === quotingMethodTypes.Hour">
                      <div :class="[defaultRowColourClass(item), hideFieldClass(item, 'rate', index, 0)]">
                        <div class="field">
                          <div class="control">
                            <vue-numeric v-if="!isReadOnlyMode && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                              class="input has-text-right"
                              :class="[ defaultRowColourClass(item), item.rate !== getLabourRateEx(item).rate ? 'is-warning' : '' ]"
                              v-model.number="item.rate"
                              :precision="2"
                              @value-changed="updateValue(index, item)" />
                            <span v-else>{{ item.rate | formatNumber($userInfo.locale) }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="has-text-info is-size-7-s has-text-right"
                        :class="hideFieldClass(item, 'rate', index, 1)">
                        <span>{{ item.rate | formatCurrency($userInfo.locale) }}</span>
                      </div>
                    </div>
                  </td>

                  <!-- Labour/Other: Hour Value -->
                  <td v-if="isSource(['labours','others','opgs'],item)"
                    class=" has-text-right money-input"
                    :class="[defaultCellColourClass(item,'hourValue'), isItemRemoved(item, getItemAssessments(item)) ? 'strike-through-line-quoting' : '']"
                    :content="getTooltipCommunicationHistoryForField(item, 'hourValue')"
                    :style="getColumnWidth('hourValue')"
                    v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'hourValue')">
                    <vue-numeric class="input has-text-right focus-target"
                      data-target="hourValue"
                      :data-sort-index="getSelectionIndex(index)"
                      :class="[defaultRowColourClass(item),defaultCellColourClass(item,'hourValue'), hideFieldClass(item,'hourValue', index, 0)]"
                      v-model.number="item.hourValue"
                      :minus="true"
                      :max="999"
                      :precision="2"
                      @blur="updateValue(index,item)" />
                    <div class="focus-trigger is-size-7-s"
                      :class="hideFieldClass(item,'hourValue', index, 1)"
                      data-target="hourValue">
                      <template v-if="innerValue.quotingMethod === quotingMethodTypes.Hour">
                        {{ item.hourValue | formatNumber($userInfo.locale) }} h
                      </template>
                      <template v-if="innerValue.quotingMethod === quotingMethodTypes.Dollar">
                        {{ item.hourValue | formatCurrency($userInfo.locale) }}
                      </template>
                    </div>
                  </td>

                  <!-- Labour Other: Dollar Value -->
                  <td v-if="isSource(['labours','others','opgs'],item)"
                    class=" has-text-right"
                    :class="[defaultCellColourClass(item,'dollarValue'), isItemRemoved(item, getItemAssessments(item)) ? 'strike-through-line-quoting' : '']"
                    :content="getTooltipCommunicationHistoryForField(item, 'dollarValue')"
                    :style="getColumnWidth('dollarValue')"
                    v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'dollarValue')">
                    <div class="is-size-7-s">{{ item.dollarValue | formatCurrency($userInfo.locale) }}</div>
                  </td>

                  <!-- PART TYPE -->
                  <td v-if="isType(itemCategoryTypes.PART,item)"
                    class=" has-text-centered is-content-width overflow-hidden"
                    :class="[isEditableField(item,'partType', index) ? 'vertical-top' : '', isItemRemoved(item, getItemAssessments(item)) ? 'strike-through-line-quoting' : '']"
                    :style="getColumnWidth('mark')"
                    :title="'Item Type'">
                    <div :class="{'select': isEditableField(item,'partType', index)}">
                      <select v-model="item.mark"
                        class="focus-target"
                        data-target="mark"
                        :data-sort-index="getSelectionIndex(index)"
                        :class="[defaultRowColourClass(item),hideFieldClass(item,'partType', index, 0)]"
                        :disabled="item.deleteOtherItem"
                        @change="updateValue(index, item, 'type', getMarkupPercent(item))">
                        <option v-for="(typeObject, type) in partTypes"
                          :value="typeObject.value"
                          :key="typeObject.value"
                          :title="typeObject.description">{{ type | formatPartCondition }}</option>
                      </select>
                    </div>

                    <span class="focus-trigger is-size-7-s overflow-hidden"
                      :class="hideFieldClass(item,'partType', index, 1)"
                      data-target="mark">{{ item.mark | formatPartType }}
                    </span>

                  </td>

                  <!-- item Quantity -->
                  <td v-if="isSource(['parts'],item)"
                    class=" has-text-right qty-input"
                    :class="[defaultCellColourClass(item,'itemQuantity'), {'vertical-top': isEditableField(item,'partType', index)}, isItemRemoved(item, getItemAssessments(item)) ? 'strike-through-line-quoting' : '']"
                    :content="getTooltipCommunicationHistoryForField(item, 'itemQuantity')"
                    :style="getColumnWidth('itemQuantity')"
                    :title="'Item Quantity: ' + item.itemQuantity"
                    v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'itemQuantity')">
                    <vue-numeric class="input has-text-right focus-target"
                      data-target="itemQuantity"
                      :data-sort-index="getSelectionIndex(index)"
                      :class="[defaultRowColourClass(item),hideFieldClass(item,'quantity', index, 0)]"
                      v-model.number="item.itemQuantity"
                      :minus="innerValue.subQuoteNo !== 0"
                      :precision="0"
                      :max="999"
                      @blur="updateValue(index, item, 'itemQuantity')" />
                    <span class="focus-trigger is-size-7-s"
                      :class="hideFieldClass(item,'quantity', index, 1)"
                      data-target="itemQuantity">{{ item.itemQuantity }}</span>
                  </td>

                  <!-- PART: TOTAL -->
                  <!--
            <td v-if="isSource(['parts'],item)"
              class=" has-text-right"
              :class="[isItemRemoved(item, getItemAssessments(item)) ? 'strike-through-line-quoting' : '']">
              <div>{{ item.itemQuantity * item.markupValue | formatCurrency($userInfo.locale) }}</div>
            </td>
            -->

                  <!-- PART: UNIT PRICE -->
                  <td v-if="isSource(['parts'],item)"
                    :class="['has-vertical-middle has-text-right money-input',defaultCellColourClass(item,'value'), {'vertical-top': isEditableField(item,'partValue', index)}, isItemRemoved(item, getItemAssessments(item)) ? 'strike-through-line-quoting' : '']"
                    :content="getTooltipCommunicationHistoryForField(item, 'value')"
                    v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'value')"
                    :style="getColumnWidth('value')">
                    <vue-numeric class="input has-text-right focus-target p-0 m-0"
                      :data-sort-index="getSelectionIndex(index)"
                      data-target="partValue"
                      :class="[defaultRowColourClass(item),defaultCellColourClass(item,'value'),hideFieldClass(item,'partValue', index, 0)]"
                      v-model.number="item.markupValue"
                      :minus="true"
                      :title="'Sell Price'"
                      :precision="2"
                      @blur="updateValue(index, item, 'markup', getMarkupPercent(item))" />
                    <span class="focus-trigger is-size-7-s"
                      :class="hideFieldClass(item,'partValue', index, 1)"
                      :title="'Sell Price'"
                      data-target="partValue">{{ item.markupValue | formatCurrency($userInfo.locale) }}</span>
                  </td>

                  <!-- MISC/SUBLETS TOTAL-->
                  <td v-if="isSource(['miscs', 'sublets'],item)"
                    class=" has-text-right money-input"
                    :class="[defaultCellColourClass(item,'value'), isItemRemoved(item, getItemAssessments(item)) ? 'strike-through-line-quoting' : '']"
                    :content="getTooltipCommunicationHistoryForField(item, 'value')"
                    :style="getColumnWidth('value')"
                    v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'value')">
                    <vue-numeric class="input has-text-right focus-target"
                      data-target="miscTotal"
                      :data-sort-index="getSelectionIndex(index)"
                      :class="[defaultRowColourClass(item),defaultCellColourClass(item,'value'), hideFieldClass(item,'miscTotal', index, 0)]"
                      v-model.number="item.value"
                      :precision="2"
                      :minus="true"
                      @input="updateValue(index,item)" />
                    <span class="focus-trigger is-size-7-s"
                      :class="hideFieldClass(item,'miscTotal', index, 1)"
                      data-target="miscTotal">{{ item.value | formatCurrency($userInfo.locale) }}</span>
                  </td>

                  <!-- BUTTONS -->
                  <td class=" has-text-right is-content-width"
                    :class="{'vertical-top': isEditableField(item,'partValue', index) && isSource(['parts'],item)}"
                    :style="getColumnWidth('action') ">

                    <div class="is-flex is-justify-content-flex-end align-center icons-container">

                      <button v-if="!audanetLock && !isItemRemoved(item, getItemAssessments(item))"
                        class="button p-0 is-small tooltip is-ghost has-text-danger"
                        @click="(e)=>deleteItemBase(e, item, index, 'deleteItem')"
                        :disabled="innerValue.readOnly || readOnlyView"
                        data-tooltip="Delete">
                        <span class="icon is-small">
                          <i class="mdi mdi-delete-outline mdi-18px" />
                        </span>
                      </button>

                      <button class="button p-0 is-small tooltip is-ghost"
                        data-tooltip="Go to PartsCheck"
                        v-if="isSource(['parts'],item) && item.partStatus"
                        @click="goToPartsCheck(item.partsCheckReferenceNo, 'quote')">
                        <span class="icon has-text-success"
                          :class="{'icon has-text-warning': item.partStatus=='Exp'}">
                          <i class="mdi mdi-checkbox-marked-outline mdi-18px" />
                        </span>
                        <label />
                      </button>

                      <button class="button p-0 is-medium tooltip is-ghost"
                        :class="{'has-text-grey': (item.quantityOrdered ?? 0) == 0 && (item.quantityReceived ?? 0) == 0,'has-text-warning': item.quantityOrdered > 0 && item.quantityReceived == 0, 'has-text-success': item.quantityOrdered < item.quantityReceived}"
                        data-tooltip="Ordering"
                        v-if="isSource(['parts', 'sublets'],item)">
                        <span class="icon is-medium">
                          <i class="mdi mdi-truck mdi-18px" />
                        </span>
                      </button>

                      <supplementary-icon v-if="innerValue.quoteAssessments.length > 0"
                        :item="item"
                        :item-assessments="getItemAssessments(item)"
                        :next-assessment-number="nextSupplemetryNo"
                        :quoting-method="innerValue.quotingMethod"
                        :icon-size="5"
                        class="p-0 supp-icon" />

                      <button class="button p-0 is-small is-ghost has-text-grey item-drag-handle"
                        :disabled="innerValue.readOnly || readOnlyView">
                        <span class="icon is-small">
                          <i class="mdi mdi-drag-vertical mdi-18px" />
                        </span>
                      </button>
                    </div>

                  </td>
                </tr>
                <tr v-if="isSource(['parts'],item) && isItemRemoved(item, getItemAssessments(item)) && openSubRows[item?.quoteItemId] == 2">
                  <td colspan="12">
                    <div class="columns ">
                      <div class="column expand-width has-vertical-middle has-text-left" />
                      <div class="column is-size-7-s has-vertical-middle has-text-left">
                        <span class="focus-trigger is-size-7-s"
                          :class="hideFieldClass(item, 'partNo', index, 1)"
                          :title="'Part No: ' + item.partNo"
                          data-target="partNo">{{ coalesce(item.partNo, 'Part Number') }}</span>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr :key="item.quoteItemId + '_subRow'"
                  v-if="openSubRows[item?.quoteItemId] == 2">
                  <td colspan="12">
                    <div class="columns is-multiline is-size-7-s">

                      <!-- NOTES -->
                      <div class="column is-2"
                        v-if="false">
                        <div class="field">
                          <label class="label">&nbsp;</label>
                          <div class="control">
                            <button class="button tooltip"
                              data-tooltip="Notes">
                              <span class="pr-1">Notes</span>
                            </button>
                          </div>
                        </div>
                      </div>

                      <!-- COPY TO & Present AS-->
                      <div class="column is-6 has-vertical-middle has-text-left">
                        <div class="columns">
                          <div class="column is-4 ml-6 pl-0 is-align-content-end">
                            <div v-if="!isItemRemoved(item, getItemAssessments(item))">
                              <div class="control">
                                <div v-if="!isReadOnlyMode"
                                  class="dropdown is-down is-left tooltip is-tooltip-topright"
                                  :class="{ 'is-active': openCopyToDropDown === item.quoteItemid }"
                                  @mouseleave="openCopyToDropDown = null"
                                  data-tooltip="Copy To">
                                  <div class="dropdown-trigger">
                                    <button class="button"
                                      @click="()=>{openCopyToDropDown = (openCopyToDropDown ===item.quoteItemid) ? openCopyToDropDown = null: openCopyToDropDown = item.quoteItemid}">
                                      <span class="has-text-black">Copy To</span>
                                      <span class="icon is-small">
                                        <i class="mdi mdi-chevron-down mdi-24px" />
                                      </span>
                                    </button>
                                  </div>
                                  <div class="dropdown-menu pt-0"
                                    role="menu">
                                    <div class="dropdown-content">
                                      <div v-for="(type, name) in getPossibleCopyToTypes(selectedItemTab)"
                                        :key="name">
                                        <a href="#"
                                          class="dropdown-item"
                                          @click="()=>{copyToType(name, item); openCopyToDropDown = null}">
                                          {{ type }}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="field column is-4">
                            <label class="label">Present as:</label>
                            <div class="control">
                              <button class="button tooltip has-text-left is-fullwidth"
                                :disabled="isItemRemoved(item, getItemAssessments(item))"
                                style="height: auto;"
                                @click="showPresentAsModal(item, index)"
                                data-tooltip="Present As">
                                <div class="columns m-0 is-vcentered">
                                  <div class="column"
                                    v-if="(item.presentAs?.length ?? 0) > 0">
                                    <div class="columns p-0 is-multiline">

                                      <div class="column is-full p-0"
                                        v-for="split in (item.presentAs ?? [])"
                                        :key="split.itemType">
                                        <div class="tags has-addons mb-0 present-as-tags">
                                          <span :class="`tag mb-1 is-${split.itemType.toLowerCase()}`">{{ split.itemType }}</span>
                                          <span class="tag is-dark mb-1">{{ split.percent }}%</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="column p-0 is-narrow"
                                    v-if="(item.presentAs?.length ?? 0) == 0">
                                    <span class="has-text-grey">Choose</span>
                                    <span class="icon is-small">
                                      <i class="mdi mdi-format-page-split mdi-20px" />
                                    </span>
                                  </div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <template v-if="isType(itemCategoryTypes.PAINT,item)">
                        <!-- BLEND -->

                        <div class="column is-1" />

                        <div class="column is-2 ml-4 has-vertical-middle has-text-centered">
                          <div class="field">
                            <label class="label">Blend</label>
                            <div class="control">

                              <div :class="[defaultRowColourClass(item), hideFieldClass(item, 'blend', index, 0)]">
                                <button class="button "
                                  :disabled="isItemRemoved(item, getItemAssessments(item))"
                                  :class="{'is-grey': !item.blend, 'is-primary': item.blend }"
                                  @click="()=>{item.blend = !item.blend;updateValue(index, item, 'blend')}">
                                  B
                                </button>
                              </div>
                              <span class="has-text-info"
                                :class="hideFieldClass(item, 'blend', index, 1)">
                                {{ item.blend ? 'B' : '' }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </template>

                      <template v-if="isType(itemCategoryTypes.RR,item) || isType(itemCategoryTypes.REP,item)">
                        <!-- WELD -->

                        <div class="column is-1" />

                        <div class="ml-4 column is-2 has-vertical-middle has-text-centered">
                          <div class="field">
                            <label class="label">Weld</label>
                            <div class="control">

                              <div :class="[defaultRowColourClass(item), hideFieldClass(item, 'weld', index, 0)]">
                                <button class="button "
                                  :disabled="isItemRemoved(item, getItemAssessments(item))"
                                  :class="{'is-grey': !item.weld, 'is-primary': item.weld }"
                                  @click="()=>{item.weld = !item.weld;updateValue(index, item)}">
                                  W
                                </button>
                              </div>
                              <span class="has-text-info"
                                :class="hideFieldClass(item, 'weld', index, 1)">
                                {{ item.weld ? 'B' : '' }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </template>

                      <template v-if="isSource(['parts'],item)">
                        <!-- UNIT COST -->
                        <div class="column is-6">
                          <div class="columns is-vcentered ">
                            <div class="column is-4">
                              <div class="has-vertical-middle money-input"
                                :class="defaultCellColourClass(item,'value')"
                                :content="getTooltipCommunicationHistoryForField(item, 'value')"
                                v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'value')">
                                <div class="field">
                                  <label class="label">Unit Cost</label>
                                  <div class="control">
                                    <vue-numeric v-if="!item.deleteOtherItem && !isReadOnlyMode"
                                      class="input has-text-right"
                                      :class="defaultRowColourClass(item)"
                                      v-model.number="item.value"
                                      :min="0"
                                      :disabled="item.deleteOtherItem || isItemRemoved(item, getItemAssessments(item))"
                                      :precision="2"
                                      @blur="updateValue(index, item, 'value', getMarkupPercent(item))" />
                                    <span v-else>{{ item.value | formatNumber($userInfo.locale) }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- MARK UP -->
                            <div class="has-vertical-middle"
                              :class="{'money-input': !item.deleteOtherItem}">
                              <div class="field ml-1 is-disabled">
                                <label class="label">Mark up</label>
                                <div class="control is-disabled">
                                  <input v-if="!item.deleteOtherItem && !isReadOnlyMode"
                                    class="input has-text-right"
                                    :class="defaultRowColourClass(item)"
                                    :value="getMarkupPercent(item) + '%'"
                                    :precision="2"
                                    :disabled="true">
                                  <span v-else>{{ item.markupValue | formatNumber($userInfo.locale) }}</span>
                                </div>
                              </div>

                            </div>

                            <div class="column is-4">
                              <div class="has-vertical-middle money-input"
                                :class="defaultCellColourClass(item,'buyPrice')"
                                :content="getTooltipCommunicationHistoryForField(item, 'buyPrice')"
                                v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'buyPrice')">
                                <div class="field">
                                  <label class="label">Buy Price</label>
                                  <div class="control">
                                    <vue-numeric v-if="!item.deleteOtherItem && !isReadOnlyMode"
                                      class="input has-text-right"
                                      :class="defaultRowColourClass(item)"
                                      v-model.number="item.buyPrice"
                                      :min="0"
                                      :disabled="item.deleteOtherItem || isItemRemoved(item, getItemAssessments(item))"
                                      :precision="2"
                                      @blur="updateValue(index, item, 'buyPrice', getMarkupPercent(item))" />
                                    <span v-else>{{ item.buyPrice | formatNumber($userInfo.locale) }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>

                      </template>

                      <template v-if="isSource(['opgs'],item)">
                        <!-- PAINT TYPE -->
                        <div class="column has-vertical-middle">
                          <div class="field">
                            <label class="label">Paint Type</label>
                            <div class="control">
                              <div v-if="vehicle && !item.deleteOtherItem && !isReadOnlyMode && !audanetLock"
                                class="select">
                                <select v-model="item.opgCode"
                                  :class="defaultRowColourClass(item)"
                                  @change="getOpgRate(item, index)">
                                  <option v-for="(paintGroup) in paintGroupTypes"
                                    :disabled="isItemRemoved(item, getItemAssessments(item))"
                                    :value="paintGroup"
                                    :key="paintGroup"
                                    :class="{ 'has-text-success' : paintGroup === vehicle.paintGroup }">{{ paintGroup }}</option>
                                </select>
                              </div>
                              <span v-else>{{ item.opgCode }}</span>
                            </div>
                          </div>

                        </div>

                        <!-- RATE -->
                        <div class="column has-vertical-middle money-input"
                          v-if="innerValue.quotingMethod === quotingMethodTypes.Hour">

                          <div class="field">
                            <label class="label">Rate</label>
                            <div class="control">
                              <vue-numeric v-if="!item.deleteOtherItem && !isReadOnlyMode && !audanetLock"
                                class="input has-text-right"
                                :class="[defaultRowColourClass(item), item.rate !== getDefaultRate(item, index) ? 'is-warning' : '']"
                                v-model.number="item.rate"
                                :min="0"
                                :precision="2"
                                :disabled="isItemRemoved(item, getItemAssessments(item))"
                                @input="updateValue(index, item)" />
                              <span v-else>{{ item.rate | formatNumber($userInfo.locale) }}</span>
                            </div>
                          </div>

                        </div>
                      </template>

                      <template v-if="isSource(['miscs'],item)">
                        <div class="column is-4 has-vertical-middle" />
                      </template>

                      <template v-if="isSource(['sublets'],item)">

                        <!-- UNIT COST -->
                        <div class="column is-2 has-vertical-middle"
                          :class="{'money-input': !item.deleteOtherItem}">
                          <div class="field">
                            <label class="label">Unit Cost</label>
                            <div class="control">
                              <vue-numeric v-if="!item.deleteOtherItem && !isReadOnlyMode"
                                class="input has-text-right"
                                :class="defaultRowColourClass(item)"
                                v-model.number="item.buyPrice"
                                :min="0"
                                :disabled="item.deleteOtherItem || isItemRemoved(item, getItemAssessments(item))"
                                :precision="2" />
                              <span v-else>{{ item.buyPrice | formatNumber($userInfo.locale) }}</span>
                            </div>
                          </div>
                        </div>

                        <!-- SUBLET type-->
                        <div class="column is-2 has-vertical-middle">
                          <div class="field">
                            <label class="label">Type</label>
                            <div class="control">
                              <div v-if="isEditableField(item,'subletType', index)"
                                class="select">
                                <select class="focus-target"
                                  :data-sort-index="getSelectionIndex(index)"
                                  style="width: 100%;"
                                  v-model="item.subletTypeId"
                                  :class="defaultRowColourClass(item)">
                                  <option v-for="sublet in sublets"
                                    :value="sublet.subletTypeId"
                                    :disabled="isItemRemoved(item, getItemAssessments(item))"
                                    :key="sublet.subletTypeId">{{ sublet.script }}</option>
                                </select>
                              </div>
                              <span v-else>{{ item.subletType }}</span>
                            </div>
                          </div>

                        </div>

                        <!-- SUBLET VENDOR -->
                        <div class="column is-2 has-vertical-middle vendor-input">

                          <div class="field">
                            <label class="label">Vendor</label>
                            <div class="control">
                              <multiselect v-if="isEditableField(item, 'subletVendor', index)"
                                class="focus-target"
                                :disabled="isItemRemoved(item, getItemAssessments(item))"
                                :data-sort-index="getSelectionIndex(index)"
                                :class="defaultRowColourClass(item)"
                                deselect-label="Can't remove this value"
                                v-model="selectedVendors[getItemVisibilityIndex(item)]"
                                track-by="id"
                                label="name"
                                placeholder=""
                                :options="vendors[getItemVisibilityIndex(item)]"
                                :loading="isVendorLoading"
                                :internal-search="false"
                                :options-limit="vendorFilter.pageSize"
                                @search-change="searchVendor($event, getItemVisibilityIndex(item))"
                                @select="selectVendor($event, getItemVisibilityIndex(item), item)"
                                :searchable="true"
                                :show-labels="false"
                                :allow-empty="true">
                                <template slot="option"
                                  slot-scope="props">
                                  <span>{{ props.option.name }}</span>
                                </template>
                                <span class="has-text-danger"
                                  slot="noResult">Vendor not found.</span>
                              </multiselect>
                              <span v-else-if="item.deleteOtherItem">{{ item.vendorName }}</span>
                            </div>
                          </div>

                        </div>

                        <div class="column is-2 has-vertical-middle has-text-centered is-content-width"
                          v-if="item.subletAuthorityDocument">
                          <a class="button is-danger is-small is-inverted tooltip is-tooltip-topright"
                            data-tooltip="Click to view"
                            @click="viewPdf(index)">
                            <span class="icon">
                              <i class="mdi mdi-24px"
                                :class="[ item.subletAuthorityDocument !== null ? 'mdi-file-pdf' : '']" />
                            </span>
                          </a>
                        </div>
                      </template>

                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </draggable>
    </template>

    <template slot="footer_empty">
      <div class="columns p-0 m-0 pt-1">
        <div class="column p-0 m-0 is-narrow">
          <div v-if="!isReadOnlyMode && Object.keys(itemTypeTabs[selectedItemTab].totals).length == 1"
            class="button is-grey-400 is-outlined has-background-white has-text-primary"
            style="border-radius: 5px;"
            @click="addBlankItem(Object.values(itemTypeTabs[selectedItemTab].totals)[0])">
            <i class="mdi mdi-plus" />
            <span class="px-3"
              style="font-size: 14px">Add Item</span>
          </div>
          <div class="field">
            <div v-if="!isReadOnlyMode && Object.keys(itemTypeTabs[selectedItemTab].totals).length > 1"
              class="dropdown is-right tooltip is-tooltip-topright"
              :class="{ 'is-active': addBlankDropdownActive }"
              @mouseleave="addBlankDropdownActive = false"
              data-tooltip="Add blank items">
              <div class="dropdown-trigger">
                <button class="button is-outlined is-primary"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu-addblank"
                  @click="()=>{addBlankDropdownActive = !addBlankDropdownActive}">
                  <span>
                    <i class="mdi mdi-shape-square-plus"
                      aria-hidden="true" /></span>
                  <span class="icon is-small">
                    <i class="mdi mdi-chevron-down mdi-24px"
                      aria-hidden="true" />
                  </span>
                </button>
              </div>
              <div class="dropdown-menu"
                id="dropdown-addblank"
                role="menu">
                <div class="dropdown-content"
                  v-for="(type, name) in itemTypeTabs[selectedItemTab].totals"
                  :key="name">
                  <a href="#"
                    class="dropdown-item"
                    @click="addBlankItem(type)">
                    {{ name }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </bulma-table>
</template>

<script>
import Guid from '@/components/Guid'
import {
  QuoteItemDifferenceMixin,
  QuoteItemValidationMixin,
  QuoteLabourMixin,
  QuoteSelectionsColumnsMixin,
  QuoteSelectionsAvailableMixin,
  QuoteSelectionsCreateItemMixin,
  QuoteAssessmentMixin
} from '../mixins'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import VueNumeric from '@/components/VueNumeric'
import StoreMixin from '../storeMixin'
import QuoteFiltersMixins from '../QuoteFiltersMixins'
import QuoteRoutes from '../route-types'
import BulmaTable from '@/components/BulmaTable'
import draggable from 'vuedraggable'
import { PartTypes, QuotingMethodTypes } from '@/enums'
import { FocusInserted } from '@/components/directives'
import Multiselect from 'vue-multiselect'
import { PartsCheckService } from '../services'
import SupplementaryIcon from './SupplementaryIcon'

export default {
  name: 'QuoteSelectionTypeTable',
  components: {
    BulmaTable,
    draggable,
    VueNumeric,
    Multiselect,
    SupplementaryIcon
  },
  filters: {
    formatPartType(value) {
      //console.log(PartTypes, value)
      const index = Object.values(PartTypes).findIndex((item) => item.value === value)
      return Object.values(PartTypes)[index]?.description
    }
  },
  directives: { FocusInserted },
  mixins: [
    NumberFiltersMixin,
    QuoteItemValidationMixin,
    QuoteLabourMixin,
    StoreMixin,
    QuoteFiltersMixins,
    QuoteSelectionsColumnsMixin,
    QuoteSelectionsAvailableMixin,
    QuoteSelectionsCreateItemMixin,
    QuoteItemDifferenceMixin,
    QuoteAssessmentMixin
  ],
  props: {
    value: {
      type: Object,
      default: null
    },
    vehicle: null,
    selectedItemTab: null,
    itemTypeTabs: {
      type: Object,
      default: () => {}
    },
    isReadOnlyMode: false,
    displayDeleted: false,
    vendors: {
      type: Array,
      default: () => []
    },
    sublets: {
      type: Array,
      default: () => []
    },
    isVendorLoading: true,
    searchVendor: {
      type: Function,
      default: () => {}
    },
    selectVendor: {
      type: Function,
      default: () => {}
    },
    vendorFilter: {
      type: Object,
      default: () => {}
    },
    selectedVendors: {
      type: Array,
      default: () => []
    },
    showPresentAsModal: {
      type: Function,
      default: () => {}
    },
    sortingIndex: {
      type: String,
      default: '000_000'
    },
    openSubRows: {
      type: Object,
      default: () => {}
    },
    toggleSubRow: {
      type: Function,
      default: () => {}
    },
    insurer: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      innerValue: this.value,
      openCopyToDropDown: null,
      itemsAssessments: [],
      filter: {
        countryId: Guid.empty(),
        companyId: Guid.empty(),
        vehicleId: this.value.vehicleId,
        query: '',
        butterflyCode: '',
        scheduleId: this.scheduleId || '',
        bodyId: this.bodyId || '',
        bodySize: '',
        paintGroup: this.vehiclePaintGroup || '',
        variantId: 0,
        nvic: '',
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 9999
      }
    }
  },
  computed: {
    activeItems() {
      var items = this.innerValue[this.itemTypeTabs[this.selectedItemTab].itemSource]
      var itemType = this.itemTypeTabs[this.selectedItemTab].itemType
      return items.filter((i) => i.itemType === itemType).filter((i) => this.isItemDeleted(i, this.getItemAssessments(i)) === false)
    },
    quoteRoutes() {
      return QuoteRoutes
    },
    partTypes() {
      return PartTypes
    },
    quotingMethodTypes() {
      return QuotingMethodTypes
    },
    dataItems() {
      return this.getFilteredItems(this.itemTypeTabs[this.selectedItemTab])
    },
    nextSupplemetryNo() {
      const nextSuppNo = Math.max(...this.value.quoteAssessments.map((i) => i.subQuoteNo)) + 1
      return nextSuppNo
    }
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        //console.log('qstt watch value, triggered')
        this.innerValue = this.value
      },
      deep: true
    }
  },
  created() {
    this.getItemsTypeAssessments()
  },
  methods: {
    handleReportOnly(event, item) {
      event.stopPropagation()
      event.preventDefault()
      item.reportOnly = !item.reportOnly
    },
    itemHasError(item, field) {
      if (item.deleted) {
        return false
      }

      let tab = this.getTabFromItem(item)
      let validator = Object.values(this.$v.entity[tab.itemSource].$each.$iter).filter((r) => r.$model.quoteItemId == item.quoteItemId)[0]
      //console.log(validator)

      if (field === undefined) {
        return validator.$anyError
      }

      return false
    },
    // CORE
    getItems(selectedItemTabFilter) {
      if (this.innerValue) {
        return this.innerValue[selectedItemTabFilter.itemSource] //.filter((i) => !i.deleted)
      }
      return []
    },
    changeLoadingPercent(item) {
      this.addUpdateRepairPaintLoading(item, true)
    },
    coalesce(value, defaultValue) {
      if (value == '') {
        return defaultValue
      }

      return value
    },
    filterItem(item, itemTabFilter) {
      if (itemTabFilter.itemType instanceof Function) {
        return itemTabFilter.itemType(item.itemType)
      }
      return item.itemType == itemTabFilter.itemType && (item.deleted ?? false) == false
    },
    getFilteredItems(itemTabFilter) {
      return this.getItems(itemTabFilter).filter((i) => this.filterItem(i, itemTabFilter))
    },
    itemMoved(event) {
      let sortNo = Math.min(...this.innerValue[this.itemTypeTabs[this.selectedItemTab].itemSource].map((i) => i.sortNo))
      this.innerValue[this.itemTypeTabs[this.selectedItemTab].itemSource].forEach((item) => {
        item.sortNo = sortNo
        sortNo++
      })
    },
    endMove(event) {},
    isRowDraggable() {
      return !this.innerValue.readOnly
    },
    sort(a, b) {
      return true
    },
    getDescriptionColSpan(item) {
      return 1
    },
    deleteItemBase(event, item, index, action) {
      event.preventDefault()
      event.stopPropagation()
      this.deleteItem(item, index)
      this.toggleSubRow(item, action)
    },
    openSubRow(e, item, action) {
      e.stopPropagation()
      e.preventDefault()

      /// only close if the subrow is different
      if (this.openSubRows[item?.quoteItemId] == 2) {
      } else this.toggleSubRow(item, action)

      // if any of the fields are clicked, i.e description, etc
      if (e.target.classList.contains('focus-trigger')) {
        this.$nextTick(() => {
          let name = e.target.dataset['target']
          let row = e.target.closest('tr')
          let elements = row.querySelectorAll(`.focus-target[data-target="${name}"]`)

          if (elements.length > 0) {
            elements[0].focus()
            let position = elements[0].value.length
            console.log(position)
            elements[0].setSelectionRange(position, position)
          }
        })
      }
      // if the item row is clicked but not any of the fields
      else {
        // if the subrow is already open, do nothing, otherwise open the subrow and focus on the description field
        if (this.openSubRows[item?.quoteItemId] != 1) {
          const name = 'itemDesc'
          const row = e.target.closest('tr')
          const elements = row.querySelectorAll(`.focus-target[data-target="${name}"]`)
          if (!!elements.length) {
            this.$nextTick(() => {
              elements[0].focus()
              let position = elements[0].value.length
              console.log(position)
              elements[0].setSelectionRange(position, position)
            })
          }
        }
      }
    },
    getMarkupPercent(item) {
      let markup = 0
      switch (item.mark) {
        case PartTypes.NEW.value:
          markup = this.innerValue.newMarkup
          break
        case PartTypes.USED.value:
          markup = this.innerValue.usedMarkup
          break
        case PartTypes.EXCH.value:
          markup = this.innerValue.exchangeMarkup
          break
        case PartTypes.RECR.value:
          markup = this.innerValue.recoreMarkup
          break
        case PartTypes.RECD.value:
          markup = this.innerValue.reconditionedMarkup
          break
        case PartTypes.ALTN.value:
          markup = this.innerValue.usedMarkup
          break
        case PartTypes.AFT.value:
          markup = this.innerValue.afterMarketMarkup
          break
        case PartTypes.CAFT.value:
          markup = this.innerValue.afterMarketMarkup
          break
        case PartTypes.AFTA.value:
          markup = this.innerValue.afterMarketMarkup
          break
      }
      return markup
    },
    getItemsTypeAssessments() {
      if (this.itemTypeTabs[this.selectedItemTab]) {
        let items = this.innerValue[this.itemTypeTabs[this.selectedItemTab].itemSource]
        if (items) {
          this.itemsAssessments = this.getItemsAssessments(items, this.innerValue.quoteAssessments)
        }
      }
    },
    getItemAssessments(item) {
      const itemAssessments = this.itemsAssessments.find((i) => i.quoteItemId === item.quoteItemId)
      if (itemAssessments) {
        return itemAssessments.assessments
      }
      return []
    },
    setItemStatus(item) {
      item.itemStatus = 'C'
    },
    updateTotal(index, item) {
      //this.isFloatingWidgetActive = true
    },
    highlightSelected(index, event) {
      // this.selectedRow = index
    },
    getPossibleCopyToTypes(currentTabName) {
      return Object.keys(this.itemTypeTabs).reduce((set, current) => {
        if (current == currentTabName) {
          return set
        }

        let currentTab = this.itemTypeTabs[current]
        Object.keys(currentTab.totals).forEach((name) => (set[currentTab.totals[name]] = name))
        return set
      }, {})
    },
    getItemVisibilityIndex(item) {
      let tab = this.getTabFromItem(item)
      let source = this.value[tab.itemSource]
      let i = 0
      for (let row of source) {
        if (row.deleted) continue
        if (item.quoteItemId == row.quoteItemId) break
        i++
      }
      return i
    },
    getSelectionIndex(index) {
      return this.sortingIndex + '_' + (index + '').padStart(5, '0')
    },
    isValidItemDescNew(item) {
      let quoteItemId = item.quoteItemId

      var validationItems = this.$v.entity[this.itemTypeTabs[this.selectedItemTab].itemSource]
      if (validationItems) {
        const index = validationItems.$model.findIndex((i) => i.quoteItemId === quoteItemId)
        if (validationItems.$each[index]) {
          return validationItems.$each[index].itemDesc.required && !validationItems.$each[index].itemDesc.$anyError
        } else {
          return false
        }
      }
      return true
    },
    getColumnWidth(name) {
      let columns = this.columns[this.itemTypeTabs[this.selectedItemTab].columns].filter((r) => r.name == name)
      if (columns.length != 1) {
        console.log(columns)
        throw `Unknown name '${name}' for '${this.itemTypeTabs[this.selectedItemTab].columns}'`
      }
      return { width: `${columns[0].width}%` }
    },
    getFullColspan() {
      return this.columns[this.itemTypeTabs[this.selectedItemTab].columns].reduce((t, r) => {
        return t + (r.colspan ?? 0)
      }, 0)
    },
    sideTagClass(side) {
      let tagClass = ''
      switch (side) {
        case 'O':
          tagClass = 'is-light'
          break
        case 'R':
          tagClass = 'is-primary'
          break
        case 'L':
          tagClass = 'is-info'
          break
      }
      return tagClass
    },
    hideFieldClass(item, fieldName, index, isLabel) {
      let isEditable = this.isEditableField(item, fieldName, index)

      let classes = []

      if (isLabel) {
        if (isEditable) {
          classes.push('is-hidden')
        }
      } else {
        if (!isEditable) {
          classes.push('is-hidden')
        }
      }

      if (item[fieldName] == '' && isLabel) {
        classes.push('has-text-grey')
      }

      return classes
    },
    isEditableField(item, type, index) {
      let typeSpecific = true
      switch (type) {
        case 'itemDesc':
          typeSpecific = !item.deleteOtherItem && !this.isReadOnlyMode && !this.audanetLock && !this.isItemRemoved(item, this.getItemAssessments(item))
          break
        case 'partNo':
          typeSpecific = !item.deleteOtherItem && !this.isReadOnlyMode && !this.isItemRemoved(item, this.getItemAssessments(item))
          break
        case 'subletType':
          typeSpecific = this.sublets && !item.deleteOtherItem && !this.isReadOnlyMode && !this.isItemRemoved(item, this.getItemAssessments(item))
          break
        case 'subletVendor':
          typeSpecific =
            this.vendors[this.getItemVisibilityIndex(item)] &&
            !item.deleteOtherItem &&
            !this.isReadOnlyMode &&
            !this.isItemRemoved(item, this.getItemAssessments(item))
          break
        case 'hourValue':
          typeSpecific = !this.isReadOnlyMode && !this.audanetLock && !this.isItemRemoved(item, this.getItemAssessments(item))
          break
        case 'partType':
          typeSpecific = !item.deleteOtherItem && !this.isReadOnlyMode && !this.isItemRemoved(item, this.getItemAssessments(item))
          break
        case 'quantity':
          typeSpecific = !this.isReadOnlyMode && !this.isItemRemoved(item, this.getItemAssessments(item))
          break
        case 'partValue':
          typeSpecific = !this.innerValue.readOnly && !this.readOnlyView && !this.isItemRemoved(item, this.getItemAssessments(item))
          break
        case 'miscTotal':
          typeSpecific = !this.isReadOnlyMode && !this.audanetLock && !this.isItemRemoved(item, this.getItemAssessments(item))
          break
        case 'expandSubRow':
          typeSpecific = this.innerValue.readOnly || (this.readOnlyView && !this.isItemRemoved(item, this.getItemAssessments(item)))
          break
        case 'loadingPercent':
          typeSpecific = !(this.innerValue.readOnly || this.readOnlyView || this.audanetLock) && !this.isItemRemoved(item, this.getItemAssessments(item))
          break
        default:
          break
      }

      return typeSpecific && this.openSubRows[item.quoteItemId] > 0
    },
    async goToPartsCheck(partsCheckReferenceNo, searchType) {
      const quoteNo =
        this.value.subQuoteNo == 0
          ? this.value.prefix + this.value.quoteNo + this.value.suffix
          : this.value.prefix + this.value.quoteNo + this.value.suffix + '/' + this.value.subQuoteNo
      let pQuoteNo = partsCheckReferenceNo == '0' ? quoteNo : quoteNo + '#' + partsCheckReferenceNo
      pQuoteNo = encodeURIComponent(pQuoteNo)
      const result = await PartsCheckService.goToPartsCheck(pQuoteNo, searchType)
      if (result) window.open(result, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.is-size-7-s {
  font-size: 15px !important;
}
.is-parent-line-clicked {
  .focus-trigger {
    border: 1px dashed transparent;
    animation: flash 1s infinite;

    @keyframes flash {
      0% {
        border-color: rgb(26, 120, 219, 0.1);
      }
      50% {
        border-color: rgb(26, 120, 219, 1);
      }
      100% {
        border-color: rgb(26, 120, 219, 0.1);
      }
    }
  }
}

.overflow-hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.xitem-row-table {
  border-spacing: 0 !important;
  border-collapse: separate !important;
  table-layout: fixed !important;
  border-radius: 5px !important;
  background-color: white;
  border: 1px solid #dce1e5;
  margin-top: 5px;
  margin-bottom: 5px;

  //inside td tag

  &.has-error {
    box-shadow: inset 0px 0px 0px 1px rgba(255, 0, 0, 1);
  }
}
.present-as-tags {
  display: inline-block;
  .tag {
    font-size: 0.6rem !important;
  }
}

.layout-fixed {
  table-layout: fixed !important;
}

.vertical-top {
  vertical-align: top !important;
}

.align-center {
  align-items: center;
}
.icons-container {
  gap: 0.5em;
}
.supp-icon {
  width: 1em;
  text-align: center;
}
tr.strike-through-line-quoting td.strike-through-line-quoting,
tr.strike-through-line-quoting div.strike-through-line-quoting {
  background: repeating-linear-gradient(180deg, #3b3b3b, #3b3b3b);
  background-size: 100% 1px;
  background-position: left center;
  background-repeat: no-repeat;
  .button {
    display: true;
  }
}
.column.expand-width {
  width: 5%;
  flex: none;
}
</style>

