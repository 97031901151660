import { ItemCategoryTypes } from '@/enums'

let labour = {}
labour[ItemCategoryTypes.PAINT] = 1 << 0
labour[ItemCategoryTypes.REP] = 1 << 1
labour[ItemCategoryTypes.RWA] = 1 << 2 // THINK THIS NEEDS TO BE 3 for future
labour[ItemCategoryTypes.RR] = 1 << 3

export default {
  LabourAllowTick: labour
}
