<template>
  <div class="tile is-ancestor is-parent full-quote-page">
    <div class="tile">
      <div class="tile is-parent is-vertical">
        <article class="tile is-child box detail-page-tile">
          <p class="title">Items Overview</p>
          <div class="tabs is-boxed">
            <ul>
              <li v-show="rrItems.length > 0"
                :class="{ 'is-active' : activeTab === itemCategoryTypes.RR }"><a @click="tabClicked(itemCategoryTypes.RR)">R&amp;R</a></li>
              <li v-show="rwaItems.length > 0"
                :class="{ 'is-active' : activeTab === itemCategoryTypes.RWA }"><a @click="tabClicked(itemCategoryTypes.RWA)">RWA</a></li>
              <li v-show="repairItems.length > 0"
                :class="{ 'is-active' : activeTab === itemCategoryTypes.REP }"><a @click="tabClicked(itemCategoryTypes.REP)">Repair</a></li>
              <li v-show="paintItems.length > 0"
                :class="{ 'is-active' : activeTab === itemCategoryTypes.PAINT }"><a @click="tabClicked(itemCategoryTypes.PAINT)">Paint</a></li>
              <li v-show="fgItems.length > 0"
                :class="{ 'is-active' : activeTab === itemCategoryTypes.FIBER }"><a @click="tabClicked(itemCategoryTypes.FIBER)">Fiber</a></li>
              <li v-show="cdItems.length > 0"
                :class="{ 'is-active' : activeTab === itemCategoryTypes.CRUSH }"><a @click="tabClicked(itemCategoryTypes.CRUSH)">Crush</a></li>
              <li v-show="crushItems.length > 0"
                :class="{ 'is-active' : activeTab === itemCategoryTypes.CD }"><a @click="tabClicked(itemCategoryTypes.CD)">C&amp;D</a></li>
              <li v-show="mechItems.length > 0"
                :class="{ 'is-active' : activeTab === itemCategoryTypes.MECH }"><a @click="tabClicked(itemCategoryTypes.MECH)">Mech</a></li>
              <li v-show="partItems.length > 0"
                :class="{ 'is-active' : activeTab === itemCategoryTypes.PART }"><a @click="tabClicked(itemCategoryTypes.PART)">Part</a></li>
              <li v-show="opgItems.length > 0"
                :class="{ 'is-active' : activeTab === itemCategoryTypes.OPG }"><a @click="tabClicked(itemCategoryTypes.OPG)">OPG</a></li>
              <li v-show="miscItems.length > 0"
                :class="{ 'is-active' : activeTab === itemCategoryTypes.MISC }"><a @click="tabClicked(itemCategoryTypes.MISC)">Misc</a></li>
              <li v-show="subletItems.length > 0"
                :class="{ 'is-active' : activeTab === itemCategoryTypes.SUBL }"><a @click="tabClicked(itemCategoryTypes.SUBL)">Sublet</a></li>
            </ul>
          </div>
          <overview-labour v-if="isLabour"
            :value="value"
            :item-type="activeTab" />
          <overview-other-labour v-if="isOtherLabour"
            :value="value"
            :item-type="activeTab" />
          <overview-part v-if="isPart"
            :value="value"
            :item-type="activeTab" />
          <overview-opg v-if="isOpg"
            :value="value"
            :item-type="activeTab" />
          <overview-misc v-if="isMisc"
            :value="value"
            :item-type="activeTab" />
          <overview-sublet v-if="isSublet"
            :value="value"
            :item-type="activeTab" />
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import { ItemCategoryTypes } from '@/enums'
import { OverviewLabour, OverviewOtherLabour, OverviewPart, OverviewOpg, OverviewMisc, OverviewSublet } from './components'

export default {
  name: 'QuoteItemsOverview',
  filters: {},
  components: {
    OverviewLabour,
    OverviewOtherLabour,
    OverviewPart,
    OverviewOpg,
    OverviewMisc,
    OverviewSublet
  },
  mixins: [],
  props: {
    value: null
  },
  data() {
    return {
      innerValue: this.value,
      activeTab: ''
    }
  },
  computed: {
    itemCategoryTypes() {
      return ItemCategoryTypes
    },
    isLabour() {
      return (
        this.activeTab === ItemCategoryTypes.RR ||
        this.activeTab === ItemCategoryTypes.RWA ||
        this.activeTab === ItemCategoryTypes.REP ||
        this.activeTab === ItemCategoryTypes.PAINT
      )
    },
    isOtherLabour() {
      return (
        this.activeTab === ItemCategoryTypes.MECH ||
        this.activeTab === ItemCategoryTypes.CD ||
        this.activeTab === ItemCategoryTypes.CRUSH ||
        this.activeTab === ItemCategoryTypes.FIBER
      )
    },
    isPart() {
      return this.activeTab === ItemCategoryTypes.PART
    },
    isOpg() {
      return this.activeTab === ItemCategoryTypes.OPG
    },
    isMisc() {
      return this.activeTab === ItemCategoryTypes.MISC
    },
    isSublet() {
      return this.activeTab === ItemCategoryTypes.SUBL
    },
    rrItems() {
      return this.value.labours.filter((i) => i.itemType === ItemCategoryTypes.RR)
    },
    rwaItems() {
      return this.value.labours.filter((i) => i.itemType === ItemCategoryTypes.RWA)
    },
    repairItems() {
      return this.value.labours.filter((i) => i.itemType === ItemCategoryTypes.REP)
    },
    paintItems() {
      return this.value.labours.filter((i) => i.itemType === ItemCategoryTypes.PAINT)
    },
    mechItems() {
      return this.value.others.filter((i) => i.itemType === ItemCategoryTypes.MECH)
    },
    cdItems() {
      return this.value.others.filter((i) => i.itemType === ItemCategoryTypes.CD)
    },
    crushItems() {
      return this.value.others.filter((i) => i.itemType === ItemCategoryTypes.CRUSH)
    },
    fgItems() {
      return this.value.others.filter((i) => i.itemType === ItemCategoryTypes.FIBER)
    },
    partItems() {
      return this.value.parts.filter((i) => i.itemType === ItemCategoryTypes.PART)
    },
    opgItems() {
      return this.value.opgs.filter((i) => i.itemType === ItemCategoryTypes.OPG)
    },
    miscItems() {
      return this.value.miscs.filter((i) => i.itemType === ItemCategoryTypes.MISC)
    },
    subletItems() {
      return this.value.sublets.filter((i) => i.itemType === ItemCategoryTypes.SUBL)
    }
  },
  watch: {},
  created() {
    this.selectInitialTab()
  },
  mounted() {},
  methods: {
    onPageChange() {},
    sort() {},
    tabClicked(type) {
      this.activeTab = type
    },
    selectInitialTab() {
      if (this.rrItems.length > 0) {
        this.activeTab = ItemCategoryTypes.RR
      } else if (this.rwaItems.length > 0) {
        this.activeTab = ItemCategoryTypes.RWA
      } else if (this.repairItems.length > 0) {
        this.activeTab = ItemCategoryTypes.REP
      } else if (this.paintItems.length > 0) {
        this.activeTab = ItemCategoryTypes.PAINT
      } else if (this.mechItems.length > 0) {
        this.activeTab = ItemCategoryTypes.MECH
      } else if (this.cdItems.length > 0) {
        this.activeTab = ItemCategoryTypes.CD
      } else if (this.crushItems.length > 0) {
        this.activeTab = ItemCategoryTypes.CRUSH
      } else if (this.fgItems.length > 0) {
        this.activeTab = ItemCategoryTypes.FIBER
      } else if (this.partItems.length > 0) {
        this.activeTab = ItemCategoryTypes.PART
      } else if (this.opgItems.length > 0) {
        this.activeTab = ItemCategoryTypes.OPG
      } else if (this.miscItems.length > 0) {
        this.activeTab = ItemCategoryTypes.MISC
      } else if (this.subletItems.length > 0) {
        this.activeTab = ItemCategoryTypes.SUBL
      }
    }
  }
}
</script>