<template>

  <div class="is-flex is-flex-direction-column ">

    <div class="is-flex is-flex-direction-row is-justify-content-space-evenly mt-2">
      <div class="tab-button is-flex is-flex-items-center is-justify-content-center"
        v-for="(itemType) in itemTypesArray"
        @click="activeTab = itemType"
        :class="{ 'is-active': itemType.orderIndex === activeTabIndex.orderIndex }"
        :key="itemType.name">
        <span class="has-text-weight-medium">
          {{ itemType?.nameShort || itemType.name }}
        </span>
      </div>
    </div>

    <div class="is-flex is-flex-direction-row w-100 mb-4 is-size-6 has-text-weight-bold px-1 mt-4">
      <div style="width: 55%;">
        Description
      </div>
      <div class="has-text-centered"
        style="width: 15%">Hour(s)</div>
      <div class="has-text-centered"
        style="width: 15%;">Cost</div>
      <div class="has-text-centered"
        style="width: 15%;"
        @change="handleSelectAll">
        <div class="pretty p-icon p-round p-smooth m-0">
          <input id="chk-item-all-template"
            type="checkbox"
            :checked="areAllCheck">
          <div class="state p-primary">
            <i class="icon mdi mdi-check" />
            <label />
          </div>
        </div>
      </div>
    </div>

    <div class="is-flex is-flex-direction-row w-100 py-1 px-1 is-clickable is-hoverable"
      @click="handleSeletItem(!allSelectedItems.includes(item.itemNo), item.itemNo)"
      v-for="item in filteredList"
      :key="item.itemId">
      <div style="width: 55%;"
        class="text-elipsis"
        :title="item.itemDesc">
        {{ item.itemDesc }}
      </div>
      <div class="has-text-centered"
        style="width: 15%">{{ '-' }}</div>
      <div class="has-text-right"
        style="width: 15%;">{{ $filters.formatCurrency(item.value, $userInfo.locale, false, 1) }}</div>
      <div class="has-text-centered"
        style="width: 15%;">
        <div class="pretty p-icon p-round p-smooth m-0">
          <input :id="'chk-' + item.itemNo"
            type="checkbox"
            :checked="allSelectedItems.includes(item.itemNo)">
          <div class="state p-primary">
            <i class="icon mdi mdi-check" />
            <label />
          </div>
        </div>
      </div>
    </div>

  </div>

</template>
<script>
import { FocusInserted } from '@/components/directives'

export default {
  name: 'QuoteTemplateView',
  components: {},
  directives: {
    FocusInserted
  },
  mixins: [],
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    templateObj: {
      type: Object,
      default: () => []
    },
    animationIn: {
      type: String,
      default: 'fadeIn'
    },
    animationOut: {
      type: String,
      default: 'fadeOut'
    },
    animationDuration: {
      type: Number,
      default: 0.5
    },
    buttonLocation: {
      type: String,
      default: 'end'
    },
    itemTypesTabs: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      selectedItems: [],
      itemsToRemove: [],
      activeTab: null // { type: null, orderIndex: null }
    }
  },
  computed: {
    disableSelect() {
      return !this.selectedItems.length && !this.itemsToRemove.length
    },
    // items that are selected in the modal and the ones that are already selected in the quote, minus the ones that are in the delete list
    allSelectedItems() {
      return [...this.selectedItems, ...this.templateObj.selectedTemplateItems].filter((x) => !this.itemsToRemove.includes(x))
    },
    areAllCheck() {
      return this.templateObj.templateList.map((x) => x.itemNo).every((x) => this.allSelectedItems.includes(x))
    },
    itemTypesArray() {
      return Object.values(this.itemTypesTabs).sort((a, b) => a.orderIndex - b.orderIndex)
    },
    activeTabIndex() {
      return this.activeTab || this.itemTypesArray[0]
    },
    filteredList() {
      return this.templateObj.templateList.filter((x) => x.itemType === this.activeTab?.itemType)
    }
  },
  watch: {
    templateObj: {
      handler(val) {
        // check if any of the items in the template are already selected in the quote
        const selectedItems = val.templateList.filter((x) => val.selectedTemplateItems.includes(x.itemNo))
        // add all the items to the selected items
        if (!selectedItems.length) {
          this.selectedItems = this.templateObj.templateList.map((x) => x.itemNo)
        }
      },
      deep: true
    }
  },
  methods: {
    select() {
      const selectedItems = this.templateObj.templateList.filter((x) => this.selectedItems.includes(x.itemNo))
      this.$emit('selectTemplateItems', { add: selectedItems, remove: this.itemsToRemove })
    },
    handleSelectAll() {
      const checked = !this.areAllCheck
      this.templateObj.templateList.forEach((x) => this.handleSeletItem(checked, x.itemNo))
    },
    handleSeletItem(event, itemNo) {
      // if true
      if (event) {
        // if item is not already selected in the quote, add it to the selected items
        if (!this.templateObj.selectedTemplateItems.includes(itemNo)) {
          this.selectedItems.push(itemNo)
        }
        // if item is already selected in the quote, but is in the delete list, remove it from the delete list
        else if (this.itemsToRemove.includes(itemNo)) {
          this.itemsToRemove = this.itemsToRemove.filter((x) => x !== itemNo)
        }
        //  if false
      } else {
        // if item is already selected in the quote, add it to the delete list
        if (this.templateObj.selectedTemplateItems.includes(itemNo)) {
          this.itemsToRemove.push(itemNo)
        }
        // if item is not already selected in the quote, but is in the selected items, remove it from the selected items
        else if (this.selectedItems.includes(itemNo)) {
          this.selectedItems = this.selectedItems.filter((x) => x !== itemNo)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-button {
  border-bottom: 2px solid #1178dd;
  width: 100%;
  cursor: pointer;

  &.is-active {
    border-bottom: 3px solid #000000;
  }

  &:hover {
    border-bottom: 3px solid #000000;
  }
}
.w-100 {
  min-width: 100%;
  max-width: 100%;
}

.is-hoverable:hover {
  background-color: #f5f5f5;
}

.text-elipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.is-flex-items-center {
  display: flex;
  align-items: center;
}
</style>

