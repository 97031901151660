<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="60"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title">
        Chose items from template: {{ templateObj?.name }}
      </p>
    </header>
    <section class="modal-card-body"
      style="max-height: 75vh;">

      <div class="is-flex is-flex-direction-column ">
        <div class="is-flex is-flex-direction-row w-100 mb-4 is-size-6 has-text-weight-bold px-1">
          <div style="width: 55%;">
            Description
          </div>
          <div class="has-text-centered"
            style="width: 30%">Hour(s)</div>
          <div class="has-text-centered"
            style="width: 15%;">

            <div class="field has-checkradio">
              <div class="control pointer"
                @click="handleSelectAll">
                <input class="is-checkradio is-primary"
                  :class="{ 'has-background-color': areAllCheck }"
                  :id="`parent-checkbox-` + templateObj.name"
                  :name="`parent-checkbox-` + templateObj.name"
                  :checked="areAllCheck"
                  type="checkbox">
                <label :for="`parent-checkbox-` + templateObj.name" />
              </div>
            </div>
          </div>
        </div>

        <div v-for="sortedItems in sortedTemplateItems"
          :key="sortedItems.orderIndex"
          class="mb-4 template-items-table">

          <div class="is-flex header"
            style="border-bottom: 1px solid gray;">
            <span class="is-size-6 has-text-weight-bold mb-1">{{ sortedItems.name }}</span>
          </div>

          <div class="is-flex is-flex-direction-row w-100 py-1 px-1 is-clickable is-hoverable"
            @click="handleSeletItem(!allSelectedItems.includes(item.itemDesc + '-' + item.itemType), item)"
            v-for="item in sortedItems.items"
            :key="item.itemId">

            <div style="width: 55%;"
              class="text-elipsis"
              :title="item.itemDesc">
              {{ item.itemDesc }}
              <strong>
                {{ item.quantity > 1 ? '(Qty: ' + item.quantity + ') ' : '' }}
              </strong>
            </div>

            <div class="has-text-centered"
              style="width: 30%">
              <span v-if="sortedItems.itemSource === 'labours'">
                {{ item.value + ' h' }}
              </span>
              <span v-else> -</span>
            </div>

            <div class="has-text-centered"
              style="width: 15%;">
              <div class="field has-checkradio">
                <div class="control">
                  <input class="is-checkradio is-primary"
                    :class="{ 'has-background-color': allSelectedItems.includes(item.itemDesc + '-' + item.itemType) }"
                    :id="`child-checkbox-` + item.itemDesc + '-' + item.itemType"
                    :name="`child-checkbox-` + item.itemDesc + '-' + item.itemType"
                    :checked="allSelectedItems.includes(item.itemDesc + '-' + item.itemType)"
                    type="checkbox">
                  <label :for="`child-checkbox-` + item.itemDesc + '-' + item.itemType" />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </section>
    <footer class="modal-card-foot"
      :style="`justify-content: flex-${buttonLocation}`">
      <button class="button is-success tooltip"
        @click="select()"
        data-tooltip="Save &amp; continue"
        :disabled="disableSelect">Select</button>
      <button class="button tooltip is-tooltip-topright"
        @click="$emit('close')"
        data-tooltip="Don't continue">Close</button>
    </footer>
  </base-modal-ex>
</template>
<script>
import { FocusInserted } from '@/components/directives'
import { BaseModalEx } from '../../components/BulmaModal'

export default {
  name: 'QuoteTemplateModal',
  components: {
    BaseModalEx
  },
  directives: {
    FocusInserted
  },
  mixins: [],
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    templateObj: {
      type: Object,
      default: () => []
    },
    animationIn: {
      type: String,
      default: 'fadeIn'
    },
    animationOut: {
      type: String,
      default: 'fadeOut'
    },
    animationDuration: {
      type: Number,
      default: 0.5
    },
    buttonLocation: {
      type: String,
      default: 'end'
    },
    itemTypeTabs: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      sortedTemplateItems: [],
      sortedTemplateItemsFlat: [],
      selectedItems: [],
      itemsToRemove: []
    }
  },
  computed: {
    disableSelect() {
      return !this.selectedItems.length && !this.itemsToRemove.length
    },
    // items that are selected in the modal and the ones that are already selected in the quote, minus the ones that are in the delete list
    allSelectedItems() {
      return [...this.selectedItems, ...this.templateObj.selectedTemplateItems].filter((x) => !this.itemsToRemove.includes(x))
    },
    areAllCheck() {
      return this.sortedTemplateItemsFlat.map((x) => x.itemDesc + '-' + x.itemType).every((x) => this.allSelectedItems.includes(x))
    }
  },
  watch: {
    templateObj: {
      handler(val) {
        this.sortTemplateItems()
      },
      deep: true
    }
  },
  mounted() {
    this.sortTemplateItems()
  },
  methods: {
    select() {
      // filter the selected items to only the ones that are not already selected in the quote
      const itemsToAdd = this.selectedItems.filter((x) => !this.templateObj.selectedTemplateItems.includes(x))
      const selectedItems = this.templateObj.templateList.filter((x) => itemsToAdd.includes(x.itemDesc + '-' + x.itemType))
      this.$emit('selectTemplateItems', { add: selectedItems, remove: this.itemsToRemove })
    },
    handleSelectAll() {
      const checked = !this.areAllCheck

      /// should be true if some/all template items are already added to the quote, and user tries to delete them
      let attemptedToDeleteQuoteItems = false

      this.sortedTemplateItemsFlat.forEach((x) => {
        const response = this.handleSeletItem(checked, x, true)
        // if quote items are already added to the quote, and user tries to delete them and attemptedToDeleteQuoteItems is false, set it to true
        if (!!response && !attemptedToDeleteQuoteItems) {
          attemptedToDeleteQuoteItems = true
        }
      })
      if (!!attemptedToDeleteQuoteItems) {
        this.$toast.open({
          message: 'Cannot delete items already added to the Quote, Please delete them from the quote to remove the items',
          type: 'is-danger',
          position: 'is-bottom',
          queue: false
        })
      }
    },
    handleSeletItem(event, item, isBatchSelect = false) {
      // if true
      if (event) {
        // if item is not already selected in the quote, add it to the selected items
        if (!this.templateObj.selectedTemplateItems.includes(item.itemDesc + '-' + item.itemType)) {
          this.selectedItems.push(item.itemDesc + '-' + item.itemType)
        }
        // if item is already selected in the quote, but is in the delete list, remove it from the delete list
        else if (this.itemsToRemove.includes(item.itemDesc + '-' + item.itemType)) {
          this.itemsToRemove = this.itemsToRemove.filter((x) => x !== item.itemDesc + '-' + item.itemType)
        }
        //  if false
      } else {
        // if item is already selected in the quote, add it to the delete list
        if (this.templateObj.selectedTemplateItems.includes(item.itemDesc + '-' + item.itemType)) {
          // this.itemsToRemove.push(item.itemDesc + '-' + item.itemType)

          if (!isBatchSelect) {
            this.$toast.open({
              message: 'Item is already added to the quote, delete it from the quote to remove the item',
              type: 'is-danger',
              position: 'is-bottom',
              queue: false
            })
          } else {
            return true
          }
        }
        // if item is not already selected in the quote, but is in the selected items, remove it from the selected items
        else if (this.selectedItems.includes(item.itemDesc + '-' + item.itemType)) {
          this.selectedItems = this.selectedItems.filter((x) => x !== item.itemDesc + '-' + item.itemType)
        }
      }

      return false
    },
    sortTemplateItems() {
      const itemsTypes = this.itemTypeTabs

      const sortedItems = Object.values(itemsTypes)
        .sort((a, b) => a.order - b.order)
        .map((x) => {
          return {
            ...x,
            items: this.templateObj.templateList.filter((y) => y.itemType === x.itemType)?.sort((a, b) => a.sortNo - b.sortNo)
          }
        })
      this.sortedTemplateItems = sortedItems?.filter((x) => x.items.length)

      const allItems = sortedItems.map((x) => x.items).flat()

      this.sortedTemplateItemsFlat = allItems

      // check if any of the items in the template are already selected in the quote
      const selectedItems = allItems.filter((x) => this.templateObj.selectedTemplateItems.includes(x.itemDesc + '-' + x.itemType))

      // add all the items to the selected items
      if (!selectedItems.length) {
        this.selectedItems = allItems.map((x) => x.itemDesc + '-' + x.itemType)
      } else {
        this.selectedItems = selectedItems.map((x) => x.itemDesc + '-' + x.itemType)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  top: 52px !important;
}

.w-100 {
  min-width: 100%;
  max-width: 100%;
}

.is-hoverable:hover {
  background-color: #f5f5f5;
}

.text-elipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.is-flex-items-center {
  display: flex;
  align-items: center;
}

.template-items-table {
  .header {
    top: -20px;
    z-index: 10;
    position: sticky !important;
    background-color: white;
  }
}
</style>

